import React from "react";
import { Switch, Route } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import head from 'lodash/head'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create dashboard routes
import routes from "routes.js";

import image1 from "assets/img/full-screen-image-1.jpg";
import image2 from "assets/img/full-screen-image-2.jpg";
import image3 from "assets/img/full-screen-image-3.jpg";
import image4 from "assets/img/full-screen-image-4.jpg";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function Admin({ clientName }) {
  const [sidebarImage, setSidebarImage] = React.useState(clientName === "regulate" ? "" : image3);
  const [sidebarBackground, setSidebarBackground] = React.useState(clientName === "regulate" ? "green" : "black");
  const handleLogout = () => {
    localStorage.removeItem("token");
    const isLocalHost = includes(process.env.REACT_APP_URI, 'localhost')

    var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;
    if (isLocalHost) {
      location.href = "http://localhost:3000/auth/login-page"
      // const urlParams = new URLSearchParams(window.location.search)
      // subdomain = urlParams.get('subdomain')
    }
    else
      location.href = `https://${subdomain}.talkapps.ai/auth/login-page`
    // const hostname = process.env.REACT_APP_URI
    // location.href = hostname === 'https://api.talkapps.ai/graphql' ? "https://app.talkapps.ai/auth/login-page" : "http://localhost:3000/auth/login-page";
  }
  const getRoutes = (routes) => {
    const filteredRoutes = compact(routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" && includes(prop.clients, clientName)) {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    }));
    if (isEmpty(filteredRoutes)) {
      handleLogout()
      return
    }
    return concat(filteredRoutes, [
      <Redirect from="/" to={head(filteredRoutes).props.path} />
    ])
  };
  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={filter(routes, route => route.layout === '/admin' && includes(route.clients, clientName))}
          image={sidebarImage}
          background={sidebarBackground}
          clientName={clientName}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          {/* <AdminFooter /> */}
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
      {/* <FixedPlugin
        setSidebarImageParent={(value) => setSidebarImage(value)}
        sidebarDefaultImage={sidebarImage}
        sidebarImages={[image1, image2, image3, image4]}
        backgroundColors={[
          "black",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
        ]}
        backgroundColor={sidebarBackground}
        setSidebarBackgroundParent={(value) => setSidebarBackground(value)}
      /> */}
    </>
  );
}

export default Admin;
