import gql from 'graphql-tag'

export const GET_SETTINGS = gql`
  query getSettings($name: String!) {
    getSettings(name: $name) {
      _id
      name
      settings
    }
  }
`

export const GET_ME = gql`
  query me {
    me {
      clientId
      clientName
      password
    }
  }
`

export const GET_CLIENT = gql`
  query getClient($name: String!) {
    getClient(name: $name) {
      _id
      name
      openAISystemMessage
      adminUsers {
        _id
        fullName
        email
        status
      }
      tutorials {
        _id
        page
        title
        videoURL
        description
        allowedSkips
      }
      lessons {
        _id
        title
        age
        lessonNumber
        videoLink
        activities
      }
      courses {
        _id
        title
        age
        description
        imageLink
        groups
        lessons {
          _id
          title
          age
          lessonNumber
          videoLink
          activities
        }
      }
      meditations {
        _id
        title
        age
        description
        audioLink
      }
      movements {
        _id
        title
        age
        description
        videoLink
      }
      visualizations {
        _id
        title
        age
        description
        audioLink
      }
      challenges {
        _id
        title
        description
        difficulty
        duration
        commitment
        imageLink
        schedule {
          title
          description
          videoLink
          lessonText
          activities
          startDay
          endDay
        }
      }
      habits {
        _id
        title
        type
        description
        options
      }
    }
  }
`

export const GET_APP_USERS = gql`
  query getAppUsers($name: String!) {
    getAppUsers(name: $name) {
      _id
      email
      active
      type
      createdAt
      subscriptions
      teamLeader {
        _id
        email
        firstname
        lastname
      }
      rewireChatDates
      dailyStreakDate
    }
  }
`

export const CHECK_COUPON = gql`
  query checkCoupon($code: String!) {
    checkCoupon(code: $code) {
      name
      code
      amountOff
      percentOff
    }
  }
`

export const VERIFY_CODE = gql`
  query verifyCode($code: String!) {
    verifyCode(code: $code)
  }
`