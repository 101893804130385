import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import compact from 'lodash/compact'
import head from 'lodash/head'
import withSession from "./withSession";
import { ADD_COURSE_TO_CLIENT, UPDATE_COURSE, DELETE_COURSE, IMAGE_UPLOAD } from "./mutations";
import Dropzone from 'react-dropzone';
import ReactQuill from "react-quill";

const MAXSIZE = 100000000 //bytes
const FILETYPES = ['application/pdf', 'image/*']

function CourseManager(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [age, setAge] = React.useState([])
    const [description, setDescription] = React.useState('')
    const [imageLink, setImageLink] = React.useState('')
    const [selectedLessons, setSelectedLessons] = React.useState([])
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editCourseId, setEditCourseId] = React.useState('')
    const [deleteCourseId, setDeleteCourseId] = React.useState('')
    // const [multipleActivities, setMultipleActivities] = React.useState("");

    const validateFile = (file) => {
      if (file.size > MAXSIZE) {
        // this.setState({ error: 'file is too big' })
        return false
      }
  
      // if (!includes(FILETYPES, file.type)) {
      //   this.setState({
      //     error: 'File Type not accepted',
      //     progress: null,
      //   })
      //   return false
      // }
      return true
    }
    const getBase64ImageFromFile = async (file) => {
      if (!file) return null
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.addEventListener(
          'load',
          function () {
            resolve(reader.result)
          },
          false,
        )
  
        reader.onerror = () => {
          return reject(this)
        }
        reader.readAsDataURL(file)
      })
    }
    const handleOnDrop = async (files, rejectedFiles) => {
      // this.setState({ error: null, progress: files.length })
      let urls = await Promise.all(
        map(files, async (file) => {
          if (validateFile(file)) {
            const url = await getBase64ImageFromFile(file)
            return url
          }
        }),
      )
      // setImageLink(head(urls))
      uploadImage({
        variables: {
          fileUrl: head(urls),
          collection: 'courses',
        }
      }).then(data => {
        setImageLink(data?.data?.uploadImage)
      })
    }


    const [addCourseToClient] = useMutation(ADD_COURSE_TO_CLIENT)
    const [updateCourse] = useMutation(UPDATE_COURSE)
    const [deleteCourse] = useMutation(DELETE_COURSE)
    const [uploadImage] = useMutation(IMAGE_UPLOAD)

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setAge([])
      setDescription('')
      setImageLink('')
      setSelectedLessons([])
      setEditCourseId('')
    }

    const handleOpenDelete = (course) => {
      setDeleteModal(true)
      setDeleteCourseId(course._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteCourseId('')
    }

    const handleAddCourse = () => {
      let course = {
        title,
        age,
        description,
        imageLink,
        lessons: selectedLessons,
        // activities
      }

      addCourseToClient({
        variables: {
          name: props.session.me.clientName,
          course
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (course) => {
      setModal(true)
      setTitle(course.title)
      setAge(course.age)
      setDescription(course.description)
      setImageLink(course.imageLink)
      setSelectedLessons(map(course.lessons, lesson => lesson._id))
      // setActivities(course.activities)
      setEditCourseId(course._id)
      setShowError('')
    }

    const handleEditCourse = async (editCourseId) => {
      const courseUpdate = {
        title,
        age,
        description,
        imageLink,
        lessons: selectedLessons,
        // activities: activities
      }
      try {
        await updateCourse({
          variables: {
            courseId: editCourseId,
            name: props.session.me.clientName,
            courseUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating course:', error);
      }
    }

    const handleDeleteCourse = async (deleteCourseId) => {
      try {
        await deleteCourse({
          variables: {
            courseId: deleteCourseId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating course:', error);
      }
    }

    const options = [
      { value: "daily check-in", label: "Daily Energy Check-in" },
      { value: "mood", label: "Mood" },
      { value: "journal", label: "Journal" },
      { value: "affirmations", label: "Affirmations" },
      { value: "body", label: "Body" },
      { value: "movement", label: "Movement" },
      { value: "breathe", label: "Breathe" },
      { value: "meditation", label: "Meditation" },
      { value: "visualization", label: "Visualization" }
    ];

    const ageOptions = [
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
      { value: "16", label: "16" },
      { value: "17", label: "17" },
      { value: "18", label: "18" },
      { value: "Adult", label: "Adult" }
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { courses, lessons, adminUsers = [] } = getClient || {}
            
            const tableData = map(courses, (course, key) => {
              return {
              id: key,
              ...course,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(course)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(course)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Course Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                    Header: "Age",
                                    accessor: "age",
                                    Cell: ({ value }) => value ? value.join(", ") : ""
                                },
                                {
                                    Header: "Course Description",
                                    accessor: "description",
                                },
                                {
                                    Header: "Image Link",
                                    accessor: "imageLink",
                                },
                                // {
                                //   Header: "Activities",
                                //   accessor: "activities",
                                //   Cell: ({ value }) => value ? value.join(", ") : ""
                                // },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                                },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Course</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                    >
                  <Modal.Header className="justify-content-center">
                    <label>{editCourseId ? 'Edit' : 'Add'} Course</label>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#" method="#">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                value={title}
                                placeholder="Title"
                                type="string"
                                onChange={event => setTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Age</label>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Ages"
                                name="multipleAgeSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={(age || []).map(a => {
                                  const matchingOption = ageOptions.find(option => option.value === a || option.value === a.value);
                                  return matchingOption || { value: a, label: a.label || a };
                                })}
                                onChange={(selectedOptions) => {
                                  // If selectedOptions is null or undefined, default to an empty array
                                  const agesValues = (selectedOptions || []).map(option => {
                                    return typeof option === 'object' ? option.value : option;
                                  });
                                
                                  setAge(agesValues);
                                }}
                                options={ageOptions} // Use the options array here
                              />
                        </Form.Group>
                        <Form.Group>
                            <label>Course Description</label>
                            <ReactQuill 
                              value={description}
                              onChange={(value) => {
                                if (value === '<p><br></p>')
                                  setDescription('')
                                else
                                  setDescription(value)
                              }}          
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Image</label>
                            <Dropzone
                              onDrop={handleOnDrop}
                              accept={
                                FILETYPES.join(',')
                              }
                              maxSize={MAXSIZE}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section style={{ padding: 0 }}>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                    >
                                      {'Upload File'}
                                    </Button>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <Form.Control
                                value={imageLink}
                                placeholder="Image Link"
                                type="string"
                                disabled
                                onChange={event => setImageLink(event.target.value)}
                                style={{ marginTop: 5, marginBottom: 5 }}
                            />
                            {imageLink && (<img src={imageLink} height="100" width="150" />)}
                            
                        </Form.Group>
                        <Form.Group>
                          <label>Lessons</label>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Choose Lessons"
                              name="multipleAgeSelect"
                              closeMenuOnSelect={false}
                              isMulti
                              value={compact((selectedLessons || []).map(a => {
                                const matchingOption = lessons.find(option => option._id === a);
                                return { value: matchingOption?._id, label: matchingOption?.title };
                              }))}
                              onChange={(selectedOptions) => {
                                // If selectedOptions is null or undefined, default to an empty array
                                const lessons = (selectedOptions || []).map(option => {
                                  return typeof option === 'object' ? option.value : option;
                                });
                              
                                setSelectedLessons(lessons);
                              }}
                              options={map(lessons, lesson => {
                                return ({
                                  value: lesson._id,
                                  label: lesson.title,
                                })
                              })} // Use the options array here
                            />
                        </Form.Group>
                        {/* <Form.Group>
                            <label>Activities</label>
                            <Form.Control
                                value={activities}
                                as="select"
                                onChange={event => setActivities(event.target.value)}
                            >
                                <option value="mood">Mood</option>
                                <option value="journal">Journal</option>
                                <option value="affirmations">Affirmations</option>
                                <option value="body">Body</option>
                                <option value="yoga">Yoga</option>
                                <option value="breath">Breath</option>
                                <option value="meditation">Meditation</option>
                                <option value="visualization">Visualization</option>
                            </Form.Control>
                        </Form.Group> */}
                      {/* <Form.Group>
                          <label>Activities</label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Choose Activities"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={(activities || []).map(activity => {
                              const matchingOption = options.find(option => option.value === activity || option.value === activity.value);
                              return matchingOption || { value: activity, label: activity.label || activity };
                            })}
                            onChange={(selectedOptions) => {
                              // If selectedOptions is null or undefined, default to an empty array
                              const activitiesValues = (selectedOptions || []).map(option => {
                                return typeof option === 'object' ? option.value : option;
                              });
                            
                              setActivities(activitiesValues);
                            }}
                            options={options} // Use the options array here
                          />
                        </Form.Group> */}
                    </Form>
                  </Modal.Body>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={handleClose}
                      variant="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={editCourseId ? () => handleEditCourse(editCourseId) : handleAddCourse}
                      variant="outlined"
                      color="primary"
                      disabled={!title || !age || !description || !imageLink}// || !activities}
                    >
                      Save
                    </Button>
                  </div>
                </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete Course</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Course? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteCourse(deleteCourseId)}
                    variant="danger"
                    color="primary"
                  >
                    Delete Course
                  </Button>
                </div>
              </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(CourseManager);
