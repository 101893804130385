import React from 'react';
import './TermsOfUse.css';  // Assuming you have a CSS file for styles

const TermsOfUse = () => {
    return (
        <div className="c6 doc-content">
            <p className="c3 c7 title" id="h.eec6vqjmr8g2"><span className="c8 c9">FeelWise App Terms of Use</span></p>
            <p className="c3"><span className="c0 c8">Last updated: April 10, 2024</span></p>
            <p className="c3">
                <span className="c1">Welcome to FeelWise! These Terms of Use ("Terms") govern your use of the FeelWise service,
                including any related applications, tools, and platforms (collectively referred to as the "Service"). The Service
                is provided by FeelWise App, a company dedicated to providing innovative communication solutions.</span>
            </p>
            <p className="c3">
                <span className="c1">By accessing or using the Service, you agree to be bound by these Terms. If you do not agree
                to these Terms, please do not use the Service.</span>
            </p>
            <ol className="c4 lst-kix_rrwqm84z08d-0 start">
                <li className="c2 li-bullet-0">
                    <span className="c0">Use of the Service</span>
                    <div className="c1">
                        <p>1.1 Eligibility: You must be at least 13 years old to use the Service. If you are under 13, you may not use the Service.</p>
                        <p>1.2 Compliance: You agree to comply with all applicable laws and regulations when using the Service.</p>
                        <p>1.3 Account: Some features of the Service may require you to create an account. You are responsible for maintaining the security of your account and for all activities that occur under your account.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">User Content</span>
                    <div className="c1">
                        <p>2.1 Ownership: You retain ownership of any content that you submit, post, or transmit through the Service ("User Content").</p>
                        <p>2.2 License: By submitting User Content, you grant FeelWise App a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such User Content in connection with the Service.</p>
                        <p>2.3 Responsibility: You are solely responsible for your User Content and the consequences of posting or publishing it.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Prohibited Conduct</span>
                    <div className="c1">
                        <p>3.1 You agree not to use the Service for any unlawful purpose or in any way that violates these Terms.</p>
                        <p>3.2 You agree not to harass, intimidate, or impersonate others when using the Service.</p>
                        <p>3.3 You agree not to engage in any activity that could disrupt, damage, or interfere with the proper functioning of the Service.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Intellectual Property</span>
                    <div className="c1">
                        <p>4.1 FeelWise App owns all rights, title, and interest in and to the Service, including all intellectual property rights.</p>
                        <p>4.2 You may not use FeelWise App's trademarks, logos, or branding without prior written consent.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Disclaimer of Warranties</span>
                    <div className="c1">
                        <p>5.1 The Service is provided "as is" and "as available" without any warranties of any kind, whether express or implied.</p>
                        <p>5.2 FeelWise App does not warrant that the Service will be uninterrupted, error-free, or free of harmful components.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Limitation of Liability</span>
                    <div className="c1">
                        <p>6.1 FeelWise App shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service.</p>
                        <p>6.2 In no event shall FeelWise App's total liability exceed the amount you paid to access the Service, if any.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Indemnification</span>
                    <div className="c1">
                        <p>7.1 You agree to indemnify and hold harmless FeelWise App, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, losses, or liabilities arising out of your use of the Service or your violation of these Terms.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Changes to the Terms</span>
                    <div className="c1">
                        <p>8.1 FeelWise App may revise these Terms at any time without notice. By continuing to use the Service after such revisions, you agree to be bound by the updated Terms.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Governing Law</span>
                    <div className="c1">
                        <p>9.1 These Terms shall be governed by and construed in accordance with the laws of the State of California, United States.</p>
                    </div>
                </li>
                <li className="c2 li-bullet-0">
                    <span className="c0">Contact Us</span>
                    <div className="c1">
                        <p>10.1 If you have any questions or concerns about these Terms, please contact us at contact@talkapps.com.</p>
                    </div>
                </li>
            </ol>
            <p className="c3"><span className="c1">By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.</span></p>
        </div>
    );
};

export default TermsOfUse;