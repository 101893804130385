import React, { useEffect, useRef, useState } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import debounce from 'lodash/debounce'
import { Form } from 'react-bootstrap';

const PaymentForm = (props) => {

  const [cardTrigger, setCardTrigger] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')

  const stripe = useStripe();
  const elements = useElements();

  const { createToken, setResetToken } = props

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setResetToken(() => () => {
      createToken(stripe, elements.getElement(CardElement), '')
    })
    //putting createToken in the dependancy array causes infinite updating
  }, [stripe, elements, setResetToken])

  const makeToken = (stripe, elements, nameOnCard) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement)
    createToken(stripe, cardElement, nameOnCard)
  }

  const debouncedMakeToken = useRef(
    debounce((stripe, elements, nameOnCard) => makeToken(stripe, elements, nameOnCard), 0)
  ).current

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    debouncedMakeToken(stripe, elements, nameOnCard)
    return () => debouncedMakeToken.cancel()
  }, [stripe, elements, cardTrigger, nameOnCard, debouncedMakeToken])
  
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        backgroundColor: 'white',
        //fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        //hidePostalCode: true,
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }
  return (
    <>
      <div
        className="checkout"
        style={{
          border: 1,
          borderStyle: 'solid',
          borderColor: '#b7babd',
          borderRadius: '3px',
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 11,
          paddingBottom: 11,
          // height: 56,
          height: 40,
          marginBottom: 5,
        }}
      >
        <CardElement
          style={{ verticalAlign: 'middle' }}
          options={CARD_ELEMENT_OPTIONS}
          onChange={() => setCardTrigger(!cardTrigger)}
          onBlur={() => setCardTrigger(!cardTrigger)}
          //hidePostalCode={true}
          onReady={props.setElement}
          //ref={(instance) => { if (props.autoFocus && instance) instance._element.on("ready", () => { instance._element.focus() }) }} 
        />
      </div>
      <Form.Group>
        <label>Name on Card</label>
        <Form.Control
          fullWidth
          placeholder="Name on Card"
          type="text"
          value={nameOnCard}
          onChange={event => setNameOnCard(event.target.value)}
          onBlur={() => setCardTrigger(!cardTrigger)}
        ></Form.Control>
      </Form.Group>
      <Form.Group></Form.Group>
    </>
  )
}

export default PaymentForm