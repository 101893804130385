import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import withSession from "./withSession";
import { ADD_TUTORIAL_TO_CLIENT, UPDATE_TUTORIAL, DELETE_TUTORIAL } from "./mutations";
import ReactQuill from "react-quill";

function TutorialManager(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [allowedSkips, setAllowedSkips] = React.useState(0)
    const [videoURL, setVideoURL] = React.useState('')
    const [page, setPage] = React.useState();
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editTutorialId, setEditTutorialId] = React.useState('')
    const [deleteTutorialId, setDeleteTutorialId] = React.useState('')
    // const [multipleActivities, setMultipleActivities] = React.useState("");


    const [addTutorialToClient] = useMutation(ADD_TUTORIAL_TO_CLIENT)
    const [updateTutorial] = useMutation(UPDATE_TUTORIAL)
    const [deleteTutorial] = useMutation(DELETE_TUTORIAL)

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setAllowedSkips(0)
      setDescription('')
      setVideoURL('')
      setPage('')
      setEditTutorialId('')
    }

    const handleOpenDelete = (tutorial) => {
      setDeleteModal(true)
      setDeleteTutorialId(tutorial._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteTutorialId('')
    }

    const handleAddTutorial = () => {
      let tutorial = {
        title,
        page,
        description,
        allowedSkips: Number(allowedSkips),
        videoURL,
      }

      addTutorialToClient({
        variables: {
          name: props.session.me.clientName,
          tutorial
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (tutorial) => {
      setModal(true)
      setTitle(tutorial.title)
      setAllowedSkips(tutorial.allowedSkips)
      setDescription(tutorial.description)
      setVideoURL(tutorial.videoURL)
      setPage(tutorial.page)
      setEditTutorialId(tutorial._id)
      setShowError('')
    }

    const handleEditTutorial = async (editTutorialId) => {
      const tutorialUpdate = {
        title,
        page,
        description,
        allowedSkips: Number(allowedSkips),
        videoURL,
      }
      try {
        await updateTutorial({
          variables: {
            tutorialId: editTutorialId,
            name: props.session.me.clientName,
            tutorialUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating tutorial:', error);
      }
    }

    const handleDeleteTutorial = async (deleteTutorialId) => {
      try {
        await deleteTutorial({
          variables: {
            tutorialId: deleteTutorialId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating tutorial:', error);
      }
    }

    const options = [
      { value: "MoodTracker", label: 'Mood' },
      { value: "Journal", label: "Journal" },
      { value: "Play", label: "Affirmations" },
      { value: "BodyMapping", label: "Body" },
      { value: "Movement", label: "Movement" },
      { value: "Breathing", label: "Breathing" },
      { value: "Meditation", label: "Meditation" },
      { value: "Visualization", label: "Visualization" }
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { tutorials = [], adminUsers = [] } = getClient || {}

            
            const tableData = map(tutorials, (tutorial, key) => {
              return {
              id: key,
              ...tutorial,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(tutorial)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(tutorial)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Instructional Page Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                  Header: "Page",
                                  accessor: 'page',
                                },
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                    Header: "Video URL",
                                    accessor: "videoURL",
                                },
                                {
                                  Header: "Description",
                                  accessor: "description",
                                },
                                {
                                  Header: "Allowed Skips",
                                  accessor: "allowedSkips",
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Video</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                  >
                    <Modal.Header className="justify-content-center">
                      <label>{editTutorialId ? 'Edit' : 'Add'} Instructional Page</label>
                    </Modal.Header>
                    <Modal.Body>
                      <Form action="#" method="#">
                          <Form.Group>
                            <label>Page</label>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Choose Activities"
                              name="multipleSelect"
                              value={options.find(option => option.value === page || option.value === page?.value) ||  { value: page, label: page?.label || page }}
                              onChange={(selectedOption) => {
                                setPage(selectedOption.value || selectedOption);
                              }}
                              options={options} // Use the options array here
                            />
                          </Form.Group>
                          <Form.Group>
                              <label>Title</label>
                              <Form.Control
                                  value={title}
                                  placeholder="Title"
                                  type="string"
                                  onChange={event => setTitle(event.target.value)}
                              />
                          </Form.Group>
                          <Form.Group>
                              <label>Allowed Skips</label>
                              <Form.Control
                                value={allowedSkips}
                                placeholder="Allowed Skips"
                                type="number"
                                onChange={event => setAllowedSkips(event.target.value)}
                              />
                          </Form.Group>
                          <Form.Group>
                              <label>Description</label>
                              <ReactQuill 
                                value={description}
                                onChange={(value) => {
                                  if (value === '<p><br></p>')
                                    setDescription('')
                                  else
                                    setDescription(value)
                                }}          
                              />
                          </Form.Group>
                          <Form.Group>
                              <label>Video Link</label>
                              <Form.Control
                                  value={videoURL}
                                  placeholder="Video Link"
                                  type="string"
                                  onChange={event => setVideoURL(event.target.value)}
                              />
                          </Form.Group>
                      </Form>
                    </Modal.Body>
                    <div className="modal-footer">
                      <Button
                        className="btn-simple"
                        onClick={handleClose}
                        variant="link"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-simple"
                        onClick={editTutorialId ? () => handleEditTutorial(editTutorialId) : handleAddTutorial}
                        variant="outlined"
                        color="primary"
                        disabled={!title || !page || !allowedSkips || !videoURL || !description}
                      >
                        Save
                      </Button>
                    </div>
                  </Modal>

                  <Modal
                    className="modal-primary"
                    onHide={handleCloseDelete}
                    show={deleteModal}
                    size='md'
                    >
                    <Modal.Header className="justify-content-center">
                      <label>Delete Tutorial</label>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to delete this Tutorial? This action cannot be undone
                    </Modal.Body>
                    <div className="modal-footer">
                      <Button
                        className="btn-simple"
                        onClick={handleCloseDelete}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-simple"
                        onClick={() => handleDeleteTutorial(deleteTutorialId)}
                        variant="danger"
                        color="primary"
                      >
                        Delete Tutorial
                      </Button>
                    </div>
                  </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(TutorialManager);
