// import React from "react";

// function ConfirmationPage() {
//   return (
//     <div className="nl-container" style={{backgroundColor: "#ffffff", width: "100%", paddingTop: 50}}>
//       <table align="center" style={{width: "600px", margin: "0 auto"}}>
//         <tbody>
//           <tr>
//             <td style={{textAlign: "center"}}>
//               <img src="https://talkapps.s3.us-west-1.amazonaws.com/feelwise/emails/feelwise-wordmark-icon.png" style={{width: "100%", height: "auto", maxWidth: "200px"}} alt="FeelWise Logo" />
//               <h3 style={{color: "#7747FF", textAlign: "center"}}>Thank you for subscribing to FeelWise!</h3>
//               <p style={{textAlign: "center"}}>Welcome to FeelWise your go-to app for unlocking the power of your emotions. To get started, click the links below for your App Store and download the FeelWise app.</p>
//               <div style={{textAlign: "center"}}>
//                 <a href="https://apps.apple.com/us/app/feelwise/id6476698990" target="_blank" rel="noopener noreferrer"><img src="https://talkapps.s3.us-west-1.amazonaws.com/feelwise/emails/apple-en.png" alt="Download on App Store" style={{width: "200px", height: "auto"}} /></a>
//               </div>
//               <div style={{textAlign: "center", paddingTop: "20px"}}>
//                 <a href="https://play.google.com/store/apps/details?id=com.talkapps.FeelWise&pcampaignid=web_share" target="_blank" rel="noopener noreferrer"><img src="https://talkapps.s3.us-west-1.amazonaws.com/feelwise/emails/google-en.png" alt="Download on Google Play" style={{width: "200px", height: "auto"}} /></a>
//               </div>
//             </td>
//             <td style={{verticalAlign: "top", paddingLeft: "20px"}}>
//               <div style={{textAlign: "center"}}>
//                 <img
//                   alt="..."
//                   style={{ width: 500 }}
//                   src={require("assets/img/FeelWiseMockup2.png")}
//                 />
//               </div>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default ConfirmationPage;

import React from "react";

import {
  Card,
  Form,
  Container,
  Row,
  Col
} from "react-bootstrap";

function ConfirmationPage() {
  return (
    <div data-image={require("assets/img/FeelWiseMockup2.png")}>
      <div className="content d-flex align-items-center">
        <Container>
          <Row style={{ textAlign: 'center', marginTop: 50 }}>
            <Col>
              <img
                alt="..."
                style={{ width: '90%', maxWidth: 400 }}
                src={require("assets/img/feelwise-wordmark-icon.png")}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mr-auto ml-auto" md="6" style={{ marginTop: 30 }}>
              <div className="trial-info" style={{ textAlign: 'start', background: '#fff', padding: '10px', borderRadius: '8px', marginBottom: 20 }}>
                <span className="trial-text" style={{ textAlign: 'start', fontWeight: '800', fontSize: 23, color: '#00B4BC' }}>Thanks for choosing FeelWise!</span>
                <span style={{ textAlign: 'start', display: 'block', marginTop: 20, marginBottom: 5, fontWeight: '500', fontSize: 18 }}>
                  {/* <span style={{ marginRight: 5 }}>▶</span> */}
                  Here Are Your Next Steps:</span>
                <span style={{ textAlign: 'start', display: 'block', marginTop: 5, marginBottom: 5, fontWeight: '375', fontSize: 15 }}>
                  <span style={{ marginRight: 5 }}>▶</span>
                  Download the FeelWise App to your device</span>
                <span style={{ textAlign: 'start',display: 'block', marginTop: 5, marginBottom: 5, fontWeight: '375', fontSize: 15 }}>
                  <span style={{ marginRight: 5 }}>▶</span>
                  Enter your username and password</span>
                <span style={{ textAlign: 'start',display: 'block', marginTop: 5, marginBottom: 5, fontWeight: '375', fontSize: 15 }}>
                  <span style={{ marginRight: 5 }}>▶</span>
                  Take the app tour and dive in!</span>
              </div>
              <Row style={{ textAlign: 'center' }}>
                <Col>
                  <a href="https://apps.apple.com/us/app/feelwise/id6476698990" target="_blank" rel="noopener noreferrer">
                    <img src="https://talkapps.s3.us-west-1.amazonaws.com/feelwise/emails/apple-en.png" alt="Download on App Store" style={{width: "200px", height: "auto"}} />
                  </a>
                </Col>
                <Col>
                  <a href="https://play.google.com/store/apps/details?id=com.talkapps.FeelWise&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                    <img src="https://talkapps.s3.us-west-1.amazonaws.com/feelwise/emails/google-en.png" alt="Download on Google Play" style={{width: "200px", height: "auto"}} />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col className="mr-auto ml-auto" md="6">
              <img
                alt="..."
                style={{ width: '100%' }}
                src={require("assets/img/FeelWiseMockup2.png")}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ConfirmationPage;