import React, { useState } from "react";

import { Button,Card, Col, Form, Modal, Row } from "react-bootstrap";

import map from 'lodash/map'
import join from 'lodash/join'
import split from 'lodash/split'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import { useMutation } from "react-apollo";
import { UPDATE_SETTINGS } from "views/mutations";
import { GET_SETTINGS } from "views/queries";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const Step1 = React.forwardRef((props, ref) => {
  const { name, settings = {}, settingData = {} } = props

  const [label, setLabel] = useState(settings[name] ? settings[name].name : '')
  const [description, setDescription] = useState(settings[name] ? settings[name].description : '')

  const [updateSettings] = useMutation(UPDATE_SETTINGS)
  
  const handleSubmit = async (event) => {
    let newSettings = cloneDeep(settings)
    // const newValue = {
    //   text: description,
    // }
    newSettings[name].name = label
    newSettings[name].description = description
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
  }

  function capitalizeWords(str) {
    // Split the string by spaces and hyphens
    return str.replace(/(\w+)([-\s]?)/g, (match, word, separator) => capitalize(word) + separator);
  }

  return (
    <div className="wizard-step" ref={ref}>
          <Row className="align-items-center">
            <Col xs="12">
              <Form>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label><h4 className="title">Name</h4></Form.Label>
                  <Form.Control value={label} onChange={event => setLabel(event.target.value)} />
                  <div style={{fontSize: 12}}>*Vibeonix emotion name: {capitalizeWords(name)}</div>
                </Form.Group>
              </Form>
              <h4 className="title">Description</h4>
              <ReactQuill 
                value={description}
                onChange={(value) => {
                  if (value === '<p><br></p>')
                    setDescription('')
                  else
                    setDescription(value)
                }}          
              />
            </Col>
          </Row>
          <div>
            If you save an empty description then we will use Vibeonix's default description instead.
          </div>
          <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
          <Row>
        <Col xs="12">
          <div className="text-right">
            <Button
              className="btn-wd btn-outline mr-1"
              type="button"
              variant="info"
              style={{ width: '100%', height: '80px', fontSize: 24 }}
              onClick={handleSubmit}
            >
              <span className="btn-label" style={{ marginRight: 10}}>
                <i class="fas fa-save"></i>
              </span>
              Save Description
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default Step1;
