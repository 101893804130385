import React, { useState } from "react";

import { Button,Card, Col, Form, Modal, Row } from "react-bootstrap";

import map from 'lodash/map'
import join from 'lodash/join'
import split from 'lodash/split'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import { useMutation } from "react-apollo";
import { UPDATE_SETTINGS } from "views/mutations";
import { GET_SETTINGS } from "views/queries";
import Select from "react-select";

const Step1 = React.forwardRef((props, ref) => {
  const { name, settings = {}, settingData = {} } = props

  const [activities, setActivities] = useState(settings[name] ? settings[name].activities : [])

  const [updateSettings] = useMutation(UPDATE_SETTINGS)
  
  const handleSubmit = async (event) => {
    let newSettings = cloneDeep(settings)
    // const newValue = {
    //   text: description,
    // }
    newSettings[name].activities = activities
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
  }

  const options = [
    { value: "journal", label: "Journal" },
    { value: "affirmations", label: "Affirmations" },
    { value: "body", label: "Body" },
    { value: "movement", label: "Movement" },
    { value: "breathe", label: "Breathe" },
    { value: "meditation", label: "Meditation" },
    { value: "visualization", label: "Visualization" }
  ];

  function capitalizeWords(str) {
    // Split the string by spaces and hyphens
    return str.replace(/(\w+)([-\s]?)/g, (match, word, separator) => capitalize(word) + separator);
  }

  return (
    <div className="wizard-step" ref={ref}>
          <Row className="align-items-center">
            <Col xs="12">
              <Form>
                <Form.Group>
                  <label>Activities</label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Choose Activities"
                    name="multipleSelect"
                    closeMenuOnSelect={false}
                    isMulti
                    value={(activities || []).map(activity => {
                      const matchingOption = options.find(option => option.value === activity || option.value === activity.value);
                      return matchingOption || { value: activity, label: activity.label || activity };
                    })}
                    onChange={(selectedOptions) => {
                      // If selectedOptions is null or undefined, default to an empty array
                      const activitiesValues = (selectedOptions || []).map(option => {
                        return typeof option === 'object' ? option.value : option;
                      });
                    
                      setActivities(activitiesValues);
                    }}
                    options={options} // Use the options array here
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
          <Row>
        <Col xs="12">
          <div className="text-right">
            <Button
              className="btn-wd btn-outline mr-1"
              type="button"
              variant="info"
              style={{ width: '100%', height: '80px', fontSize: 24 }}
              onClick={handleSubmit}
            >
              <span className="btn-label" style={{ marginRight: 10}}>
                <i class="fas fa-save"></i>
              </span>
              Save Activities
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default Step1;
