import React from "react";
import { Helmet } from "react-helmet";

const DynamicHead = ({ site }) => {
  const configs = {
    "feelwise.talkapps.ai": {
      title: "FeelWise",
      favicon: "favicon.png",
    },
    "ultimind.talkapps.ai": {
      title: "Ultimind",
      favicon: "favicon.png",
    },
    "mendedlight.talkapps.ai": {
      title: "MendedLight",
      favicon: "favicon.png",
    },
    "regulate.talkapps.ai": {
      title: "Regulate",
      favicon: "favicon.png",
    },
    "lumify.talkapps.ai": {
      title: "Lumify",
      favicon: "favicon.png",
    },
    default: {
      title: "TalkApps",
      favicon: "favicon.png",
    },
  };

  const config = configs[site] || configs["default"];

  return (
    <Helmet>
      <title>{config.title}</title>
      <link rel="icon" type="image/x-icon" href={config.favicon} />
    </Helmet>
  );
};

export default DynamicHead;
