import React from 'react';
import './PrivacyPolicy.css';  // Assuming you have a CSS file for styles

const PrivacyPolicy = () => {
    return (
        <div className="doc-content">
            <h1 className="title">Privacy Policy for Talk Apps</h1>
            <p><strong>Effective:</strong> April 10, 2024</p>
            <p>Welcome to Talk Apps! We value your privacy and are dedicated to maintaining the security of any information we collect from or about you. Below, we outline our updated Privacy Policy effective April 10, 2024.</p>
            <p>Please note that these updates do not apply to individuals located in the European Economic Area, UK, and Switzerland. If you reside in these areas, a different version of our Privacy Policy applies to you.</p>
            
            <h2>Personal Information Collection</h2>
            <p>We, Talk Apps OpCo, LLC, and our affiliates ("Talk Apps," "we," "our," or "us"), collect personal information ("Personal Information") as described below:</p>
            <ul>
                <li>Account Information: Information associated with your account creation, including your name, contact details, account credentials, payment card information, and transaction history.</li>
                <li>User Content: Personal Information included in the input, file uploads, or feedback you provide to our Services.</li>
                <li>Communication Information: Details such as your name, contact information, and message contents when you communicate with us.</li>
                <li>Social Media Information: Information provided by you on our social media pages.</li>
                <li>Other Information You Provide: Additional information you may provide to us, such as participation in events or surveys.</li>
            </ul>

            <h3>Use of Personal Information</h3>
            <ul>
                <li>Providing, administering, and analyzing our Services.</li>
                <li>Improving our Services and conducting research.</li>
                <li>Communication with you regarding our Services and events.</li>
                <li>Developing new programs and services.</li>
                <li>Fraud prevention and ensuring the security of our systems.</li>
                <li>Business transfers and legal compliance.</li>
            </ul>

            <h3>Disclosure of Personal Information</h3>
            <ul>
                <li>Vendors and Service Providers: To assist in business operations and perform services.</li>
                <li>Business Transfers: In the event of strategic transactions or reorganization.</li>
                <li>Legal Requirements: To comply with legal obligations or protect our rights.</li>
                <li>Affiliates: To our affiliated entities in accordance with this Privacy Policy.</li>
                <li>Business Account Administrators: For users of Talk Apps Enterprise or business accounts.</li>
                <li>Other Users and Third Parties: With your consent or through features allowing sharing.</li>
            </ul>

            <h3>Your Rights</h3>
            <ul>
                <li>Access, deletion, rectification, or updating of your Personal Information.</li>
                <li>Data portability and restriction of processing.</li>
                <li>Withdrawal of consent and objection to processing.</li>
                <li>Lodge a complaint with your local data protection authority.</li>
            </ul>

            <p>If you have any questions or concerns about this Privacy Policy, please contact support.</p>
            <p>Thank you for using Talk Apps. Your privacy is important to us.</p>
        </div>
    );
};

export default PrivacyPolicy;
