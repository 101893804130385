import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import isNil from 'lodash/isNil'
import withSession from "./withSession";
import { CHANGE_OPEN_AI_SYSTEM } from "./mutations";
import { Eye, EyeSlash } from "react-bootstrap-icons";

function Chat(props) {

    const [newOpenAISystemMessage, setOpenAISystemMessage] = React.useState()

    const [changeOpenAISystem] = useMutation(CHANGE_OPEN_AI_SYSTEM)

    const handleChangeOpenAISystem = () => {
      changeOpenAISystem({
        variables: {
          clientId: props.session.me.clientId,
          openAISystem: newOpenAISystemMessage,
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        setOpenAISystemMessage()
      }).catch(error => {
        setShowError(error.message)
      })
    }

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { openAISystemMessage = '' } = getClient || {}
            return (
              <>
                <Container fluid>
                  <h4 className="title">Chat System</h4>
                  {/* <p className="category">
                    A powerful react plugin handcrafted by our friends from{" "}
                    <a
                      href="https://react-table.tanstack.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      react-table
                    </a>
                    . It is a highly flexible tool, based upon the foundations of
                    progressive enhancement on which you can add advanced interaction
                    controls. Please check out their{" "}
                    <a
                      href="https://react-table.tanstack.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      full documentation.
                    </a>
                  </p> */}
                  <Card>
                    <Card.Body>
                      <Form.Group>
                        <label>System Message</label>
                        <Form.Control
                            as="textarea" 
                            rows={12} 
                            defaultValue={openAISystemMessage}
                            value={newOpenAISystemMessage}
                            placeholder="Set chat system"
                            onChange={event => setOpenAISystemMessage(event.target.value)}
                        />  
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <Button disabled={isNil(newOpenAISystemMessage)} onClick={() => handleChangeOpenAISystem()} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, marginTop: 10, fontSize: 16, fontWeight: 'bold' }}>Save Changes</Button>
                </Container>
              </>
            )
          }}
        </Query>
      );
}

export default withSession(Chat);
