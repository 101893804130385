import withSession from "views/withSession"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import isNil from 'lodash/isNil'

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

const router = props => {
  const { session = {} } = props
  const { me } = session || {}
  const loggedIn = !isNil(me)
  return (
    <BrowserRouter>
        {loggedIn ? (
          <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} clientName={me.clientName} />} />
            <Redirect from="/" to="/admin" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="/" to="/auth/login-page" />
          </Switch>
        )}
    </BrowserRouter>
  )
}

export default withSession(router)