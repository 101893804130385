import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

const test = async () => {

  // let token = ""
  
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({
  //     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0YWxrQXBwIiwibmFtZSI6IkRhbm55IENsYXJrIiwiaWF0IjoxNTE2MjM5MDIyfQ.m_HpYdn5AD9PacTQb3NT4fktdWmbQzFsRgy7eFwIJK4'
  //   })
  // };
  
  // fetch('https://realm.mongodb.com/api/client/v2.0/app/data-qaiob/auth/providers/custom-token/login', requestOptions)
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log(data)
  //     token = data.access_token
  //     }
  //     )
  //   .catch(error => console.error(error));

  
  
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Request": "*",
  };
console.log(headers)
  const data = {
    "dataSource": "Vibeonix",
    "database": "sample_mflix",
    "collection": "comments",
    "document": {
      "text": "Hello from the Data API!!@#$%@"
    }
  };

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  };



  await fetch('/api', options)
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error));

}

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Company
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={test}>
                  Blog
                </a>
              </li>
            </ul>
            <p className="copyright text-center">
              © <script>document.write(new Date().getFullYear())</script>
              <a href="http://www.creative-tim.com">Creative Tim</a>, made with
              love for a better web
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
