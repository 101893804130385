import React, { useState } from "react";
import Select from "react-select";
import Lesson from "./LessonManager";
import Meditation from "./MeditationManager";
import Movement from "./MovementManager";
import Visualization from "./VisualizationManager";
import Course from "./CourseManager";
import Challenge from "./ChallengeManager";
import Habit from "./HabitManager";
import withSession from "./withSession";

import filter from "lodash/filter";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";

const ContentManager = (props) => {
  // Default option object for 'Lesson'
  const defaultOption = { value: "lesson", label: "Lesson" };

  // State to track the selected option, initialized to the Lesson option
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  // Options for the select, with label and value properties
  const options = [
    { value: "lesson", label: "Lesson" },
    { value: "meditation", label: "Meditation" },
    { value: "movement", label: "Movement" },
    { value: "visualization", label: "Visualization" },
    {
      value: "challenge",
      label: "Challenge",
      access: ["feelwise", "regulate", "lumify"],
    },
    {
      value: "course",
      label: "Course",
      access: ["feelwise", "regulate", "lumify"],
    },
    {
      value: "habit",
      label: "Habit",
      access: ["feelwise", "regulate", "lumify"],
    },
  ];

  // Function to handle selection changes
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Function to render the appropriate component based on selection
  const renderContent = () => {
    switch (selectedOption?.value) {
      case "lesson":
        return <Lesson />;
      case "meditation":
        return <Meditation />;
      case "movement":
        return <Movement />;
      case "visualization":
        return <Visualization />;
      case "course":
        return <Course />;
      case "challenge":
        return <Challenge />;
      case "habit":
        return <Habit />;
      default:
        return <Lesson />;
    }
  };

  return (
    <div>
      <h3 className="title">Content Page</h3>
      <Select
        className="react-select info"
        classNamePrefix="react-select"
        placeholder="Choose Content"
        name="contentDropdown"
        closeMenuOnSelect={true}
        value={selectedOption} // Controlled by the selectedOption state
        onChange={handleChange}
        options={filter(options, (option) => {
          return (
            isEmpty(option.access) ||
            includes(option.access, props.session.me.clientName)
          );
        })}
      />
      {renderContent()}
    </div>
  );
};

export default withSession(ContentManager);
