import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { Button, Card, Col, Form, FormGroup, FormControl, FormLabel, Row, Modal } from "react-bootstrap";
import ReactPlayer from 'react-player';
// react component that creates a dropdown menu for selection
import Select from "react-select";
import { UPDATE_SETTINGS } from "views/mutations";
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import { GET_SETTINGS } from "views/queries";
import map from "lodash/map";
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import filter from 'lodash/filter'
import SweetAlert from "react-bootstrap-sweetalert";

const VideoCard = ({ videoUrl, title }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Button onClick={() => setShow(true)}>
      <i className="nc-icon nc-tv-2 icon-bold" style={{ marginRight: 10, }}></i>
        Watch
      </Button>
      <Modal 
        show={show}
        onHide={() => setShow(false)}
        size="md"
        dialogClassName="modal-dialog-centered"
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer height={300} width={450} url={videoUrl} controls={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Step3 = React.forwardRef((props, ref) => {
  const videoUrl = 'https://vimeo.com/123456789'; // Replace with your Vimeo or YouTube video URL
  // const title = 'Video Title'; // Replace with your desired title

  const { name, settings = {}, settingData = {} } = props

  const [showEdit, setShowEdit] = useState(false)
  const [openEdit, setOpenEdit] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [action, setAction] = useState('')
  const [content, setContent] = useState([''])

  const [alertState, setAlertState] = useState(null)

  const addNewContent = () => {
    let newContent = [...content]
    newContent.push('')
    setContent(newContent)
  }

  const handleSetContent = (value, index) => {
    let newContent = [...content]
    newContent[index] = value
    setContent(newContent)
  }

  const handleOpenEdit = (index) => {
    if (index < 0) {
      setTitle('')
      setDescription('')
      setAction('sit')
      setContent([''])
      setOpenEdit(index)
    } else {
      const editTarget = settingData.experiences[index] || {}
      setTitle(editTarget.title)
      setDescription(editTarget.description)
      setAction(editTarget.action)
      setContent(!isEmpty(editTarget.content) ? editTarget.content : [''])
      setOpenEdit(index)
    }
  }

  const handleCloseEdit = () => {
    setTitle('')
    setDescription('')
    setAction('')
    setContent([''])
    setOpenEdit(null)
  }

  const [updateSettings] = useMutation(UPDATE_SETTINGS)

  const handleSubmit = async (event) => {
    let newSettings = cloneDeep(settings)
    const newValue = {
      title,
      description,
      action,
      content: compact(content),
    }
    newSettings[name].experiences.push(newValue)
    if (filter(newSettings[name].experiences, experience => experience.action === action).length > 1) {
      setAlertState('Action: ' + (action === 'sit' ? "Sit with it" : action === 'work' ? "Work through it" : action === 'learn' ? "Learn about it" : '') + ", is already being used for an experience")
      return
    }
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
    handleCloseEdit()
  }

  const handleSubmitEdit = async (event) => {
    let newSettings = cloneDeep(settings)
    const newValue = {
      title,
      description,
      action,
      content: compact(content),
    }
    newSettings[name].experiences[openEdit] = newValue
    if (filter(newSettings[name].experiences, experience => experience.action === action).length > 1) {
      setAlertState('Action: ' + (action === 'sit' ? "Sit with it" : action === 'work' ? "Work through it" : action === 'learn' ? "Learn about it" : '') + ", is already being used for an experience")
      return
    }
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
    handleCloseEdit()
  }

  return (
    <div className="wizard-step" ref={ref}>
        {map(settingData.experiences, (experience, index) => (
          <>
            <Row className="align-items-center">
              <Col xs="10">
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <h3>{experience.title}</h3>
                </Row>
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <p>{experience.description}</p>
                </Row>
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <p>{experience.action === 'sit' ? "Sit with it" : experience.action === 'work' ? "Work through it" : experience.action === 'learn' ? "Learn about it" : ''}</p>
                </Row>
                {map(experience.content, content => (
                  <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                    <div>
                      {ReactPlayer.canPlay(content) ? <VideoCard videoUrl={content} title={experience.title} /> : <Button href={content} target="_blank"><i className="nc-icon nc-single-copy-04 icon-bold" style={{ marginRight: 10, }}></i>View</Button>}
                    </div>
                  </Row>
                ))}
              </Col>
              <Col >
                <Button onClick={() => handleOpenEdit(index)} style={{ borderRadius: 50, height: 50, width: 50, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                  <i className="fas fa-pen" style={{ marginLeft: -0, fontSize: 20, marginTop: 2, color: 'white' }}></i>
                </Button>
              </Col>
            </Row>
            <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
          </>
        ))}
        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button
                className="btn-wd btn-outline mr-1"
                type="button"
                variant="info"
                style={{ width: '100%', height: '80px', fontSize: 24 }}
                onClick={() => handleOpenEdit(-1)}
              >
                <span className="btn-label" style={{ marginRight: 10}}>
                  <i className="fas fa-plus"></i>
                </span>
                Add Experience
              </Button>
            </div>
          </Col>
        </Row>
        <Modal
          show={!isNil(openEdit)}
          onHide={handleCloseEdit}
          size='xl'
          dialogClassName="modal-dialog-centered"
          closeButton
          // contentClassName="modal-content"
          // style={{ height: '100%' }}
        >
          <Modal.Header closeButton>
              <Modal.Title as="h4">{openEdit >= 0 ? 'Edit' : 'Add'} Experience</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col xs="12">
              <Form>
              <Form.Group className="mb-3" controlId="">
                  <Form.Label>Title</Form.Label>
                  <Form.Control as="textarea" value={title} onChange={event => setTitle(event.target.value)} rows={1} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" value={description} onChange={event => setDescription(event.target.value)} rows={10} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Action</Form.Label>
                  <Form.Control as="select" value={action} onChange={event => setAction(event.target.value)}>
                    <option selected value="sit">Sit with it</option>
                    <option value="work">Work through it</option>
                    <option value="learn">Learn about it</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    Content
                    <Button onClick={addNewContent} style={{ marginLeft: 10, borderRadius: 50, height: 35, width: 35 }}>
                      <i className="nc-icon nc-simple-add" style={{ fontWeight: 800, marginLeft: -6 }}></i>
                    </Button>
                  </Form.Label>
                  {map(content, (item, index) => (
                    <Form.Control as="textarea" value={item} style={{ marginBottom: 5, }} onChange={event => handleSetContent(event.target.value, index)} rows={1} />
                  ))}
                </Form.Group>
              </Form>
            </Col>
            <Col xs="12">
              <div className="text-right">
                <Button
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant="info"
                  onClick={openEdit >= 0 ? handleSubmitEdit : handleSubmit}
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  {openEdit >= 0 ? 'Edit' : 'Add'} Experience
                </Button>
              </div>
            </Col>
          </Modal.Body>
          {alertState && (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-100px" }}
              title="Error"
              onConfirm={() => setAlertState(null)}
              onCancel={() => setAlertState(null)}
              confirmBtnBsStyle="info"
            >
              {alertState}
            </SweetAlert>
          )}
        </Modal>
    </div>
  );
});

export default Step3;
