import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Tab,
    Tabs,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import compact from 'lodash/compact'
import unset from 'lodash/unset'
import head from 'lodash/head'
import set from 'lodash/set'
import cloneDeep from "lodash/cloneDeep";
import fill from 'lodash/fill'
import slice from 'lodash/slice'
import withSession from "./withSession";
import { ADD_CHALLENGE_TO_CLIENT, UPDATE_CHALLENGE, DELETE_CHALLENGE, IMAGE_UPLOAD, PUBLISH_CHALLENGE } from "./mutations";
import Dropzone from "react-dropzone";
import ReactQuill from "react-quill";

const MAXSIZE = 100000000 //bytes
const FILETYPES = ['application/pdf', 'image/*']

function ChallengeManager(props) {
    const [modal, setModal] = React.useState(false);
    const [publishModal, setPublishModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [difficulty, setDifficulty] = React.useState('')
    const [duration, setDuration] = React.useState(0)
    const [commitment, setCommitment] = React.useState('')
    const [imageLink, setImageLink] = React.useState('')
    const [scheduleItems, setScheduleItems] = React.useState([]);
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [activeItem, setActiveItem] = React.useState({value: 0, label: 'Day 1'})

    const [editChallengeId, setEditChallengeId] = React.useState('')
    const [publishChallengeId, setPublishChallengeId] = React.useState('')
    const [deleteChallengeId, setDeleteChallengeId] = React.useState('')
    // const [multipleActivities, setMultipleActivities] = React.useState("");


    const [addChallengeToClient] = useMutation(ADD_CHALLENGE_TO_CLIENT)
    const [updateChallenge] = useMutation(UPDATE_CHALLENGE)
    const [publishChallenge] = useMutation(PUBLISH_CHALLENGE)
    const [deleteChallenge] = useMutation(DELETE_CHALLENGE)
    const [uploadImage] = useMutation(IMAGE_UPLOAD)

    const validateFile = (file) => {
      if (file.size > MAXSIZE) {
        // this.setState({ error: 'file is too big' })
        return false
      }
  
      // if (!includes(FILETYPES, file.type)) {
      //   this.setState({
      //     error: 'File Type not accepted',
      //     progress: null,
      //   })
      //   return false
      // }
      return true
    }
    const getBase64ImageFromFile = async (file) => {
      if (!file) return null
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.addEventListener(
          'load',
          function () {
            resolve(reader.result)
          },
          false,
        )
  
        reader.onerror = () => {
          return reject(this)
        }
        reader.readAsDataURL(file)
      })
    }
    const handleOnDrop = async (files, rejectedFiles) => {
      // this.setState({ error: null, progress: files.length })
      let urls = await Promise.all(
        map(files, async (file) => {
          if (validateFile(file)) {
            const url = await getBase64ImageFromFile(file)
            return url
          }
        }),
      )
      // setImageLink(head(urls))
      uploadImage({
        variables: {
          fileUrl: head(urls),
          collection: 'courses',
        }
      }).then(data => {
        setImageLink(data?.data?.uploadImage)
      })
    }

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setDescription('')
      setDuration(0)
      setDifficulty('')
      setCommitment('')
      setImageLink('')
      setScheduleItems([])
      setActiveItem({value: 0, label: 'Day 1'})
      setEditChallengeId('')
    }

    const handleOpenPublish = (challenge) => {
      setPublishModal(true)
      setPublishChallengeId(challenge._id)
    }

    const handleClosePublish = (user) => {
      setPublishModal(false)
      setPublishChallengeId('')
    }

    const handleOpenDelete = (challenge) => {
      setDeleteModal(true)
      setDeleteChallengeId(challenge._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteChallengeId('')
    }

    const handleChangeDuration = (value) => {
      setDuration(value)
      let newScheduleItems = cloneDeep(scheduleItems)
      if (newScheduleItems.length > value)
        newScheduleItems = slice(newScheduleItems, 0, value)
      while (newScheduleItems.length < value) {
        newScheduleItems.push({})
      }
      setScheduleItems(newScheduleItems)
    }

    const onChangeScheduleItem = (value, name) => {
      let newScheduleItems = [ ...scheduleItems ]
      set(newScheduleItems, `${activeItem.value}.${name}`, value)
      setScheduleItems(newScheduleItems)
    }

    const handleAddChallenge = () => {
      let challenge = {
        published: false,
        title,
        description,
        difficulty,
        duration,
        commitment,
        imageLink,
        schedule: map(scheduleItems, (item, index) => {
          let adjustedItem = item
          unset(adjustedItem, '_id')
          unset(adjustedItem, '__typename')
          adjustedItem.startDay = index + 1
          adjustedItem.endDay = index + 1
          let tasks = []
          if (adjustedItem.videoLink)
            tasks.push({
              label: "Watch today's video"
            })
          map(adjustedItem.activities, activity => {
            const matchingOption = options.find(option => option.value === activity || option.value === activity.value)
            tasks.push({
              label: matchingOption.label
            })
          })
          adjustedItem.tasks = tasks
          return adjustedItem
        })
      }

      addChallengeToClient({
        variables: {
          name: props.session.me.clientName,
          challenge
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (challenge) => {
      setModal(true)
      setTitle(challenge.title)
      setDescription(challenge.description)
      setDuration(challenge.duration)
      setDifficulty(challenge.difficulty)
      setCommitment(challenge.commitment)
      setImageLink(challenge.imageLink)
      setScheduleItems(cloneDeep(challenge.schedule))
      setEditChallengeId(challenge._id)
      setShowError('')
    }

    const handleEditChallenge = async (editChallengeId) => {
      const challengeUpdate = {
        title,
        description,
        difficulty,
        duration,
        commitment,
        imageLink,
        schedule: map(scheduleItems, (item, index) => {
          let adjustedItem = item
          unset(adjustedItem, '_id')
          unset(adjustedItem, '__typename')
          adjustedItem.startDay = index + 1
          adjustedItem.endDay = index + 1
          let tasks = []
          if (adjustedItem.videoLink)
            tasks.push({
              label: "Watch today's video"
            })
          map(adjustedItem.activities, activity => {
            const matchingOption = options.find(option => option.value === activity || option.value === activity.value)
            tasks.push({
              label: matchingOption.label
            })
          })
          adjustedItem.tasks = tasks
          return adjustedItem
        })
      }
      try {
        await updateChallenge({
          variables: {
            challengeId: editChallengeId,
            name: props.session.me.clientName,
            challengeUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating challenge:', error);
      }
    }

    const handlePublishChallenge = async (publishChallengeId) => {
      try {
        await publishChallenge({
          variables: {
            challengeId: publishChallengeId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleClosePublish()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating challenge:', error);
      }
    }

    const handleDeleteChallenge = async (deleteChallengeId) => {
      try {
        await deleteChallenge({
          variables: {
            challengeId: deleteChallengeId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error deleting challenge:', error);
      }
    }

    const options = [
      { value: "daily check-in", label: "Daily Energy Check-in" },
      { value: "mood", label: "Mood" },
      { value: "journal", label: "Journal" },
      { value: "affirmations", label: "Affirmations" },
      { value: "body", label: "Body" },
      { value: "movement", label: "Movement" },
      { value: "breathe", label: "Breathe" },
      { value: "meditation", label: "Meditation" },
      { value: "visualization", label: "Visualization" }
    ];

    const ageOptions = [
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
      { value: "16", label: "16" },
      { value: "17", label: "17" },
      { value: "18", label: "18" },
      { value: "Adult", label: "Adult" }
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { challenges, adminUsers = [] } = getClient || {}
            
            const tableData = map(challenges, (challenge, key) => {
              return {
              id: key,
              ...challenge,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="success"
                    size="sm"
                    className="text-success btn-link edit"
                    onClick={() => handleOpenPublish(challenge)}
                  >
                    <i className="fa fa-paper-plane" />
                  </Button>{" "}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(challenge)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(challenge)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Challenge Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                    Header: "Challenge Description",
                                    accessor: "description",
                                },
                                {
                                  Header: "Challenge duration",
                                  accessor: "duration",
                                },
                                {
                                  Header: "Challenge Commitment",
                                  accessor: "commitment",
                                },
                                {
                                    Header: "Image Link",
                                    accessor: "imageLink",
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Challenge</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                    >
                  <Modal.Header className="justify-content-center">
                    <label>{editChallengeId ? 'Edit' : 'Add'} Challenge</label>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#" method="#">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                value={title}
                                placeholder="Title"
                                type="string"
                                onChange={event => setTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Challenge Description</label>
                            <ReactQuill 
                              value={description}
                              onChange={(value) => {
                                if (value === '<p><br></p>')
                                  setDescription('')
                                else
                                  setDescription(value)
                              }}          
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Challenge Duration</label>
                            <Form.Control
                                value={duration}
                                placeholder="Challenge Duration"
                                type="number"
                                onChange={event => handleChangeDuration(Math.floor(event.target.value))}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Challenge Difficulty</label>
                            <Form.Control
                                value={difficulty}
                                placeholder="Challenge Difficulty"
                                type="string"
                                onChange={event => setDifficulty(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Challenge Commitment</label>
                            <Form.Control
                                value={commitment}
                                placeholder="Challenge Commitment"
                                type="string"
                                onChange={event => setCommitment(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Image</label>
                            <Dropzone
                              onDrop={handleOnDrop}
                              accept={
                                FILETYPES.join(',')
                              }
                              maxSize={MAXSIZE}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section style={{ padding: 0 }}>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                    >
                                      {'Upload File'}
                                    </Button>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <Form.Control
                                value={imageLink}
                                placeholder="Image Link"
                                type="string"
                                disabled
                                onChange={event => setImageLink(event.target.value)}
                                style={{ marginTop: 5, marginBottom: 5 }}
                            />
                            {imageLink && (<img src={imageLink} height="100" width="150" />)}
                        </Form.Group>
                        {duration > 0 && (
                          <>
                            <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                              placeholder="Choose Day"
                              name="dayDropdown"
                              closeMenuOnSelect={true}
                              value={activeItem} // Controlled by the selectedOption state
                              onChange={(value) => setActiveItem(value)}
                              options={map(scheduleItems, (item, index) => {
                                return { value: index, label: `Day ${index + 1}` }
                              })}
                            />
                            <Form.Group>
                              <label>Schedule Title</label>
                              <Form.Control
                                  value={scheduleItems[activeItem.value]?.title || ''}
                                  placeholder="Schedule Title"
                                  type="string"
                                  onChange={event => onChangeScheduleItem(event.target.value, 'title')}
                              />
                            </Form.Group>
                            <Form.Group>
                                <label>Schedule Description</label>
                                <ReactQuill 
                                  value={scheduleItems[activeItem.value]?.description || ''}
                                  onChange={(value) => {
                                    if (value === '<p><br></p>')
                                      onChangeScheduleItem('', 'description')
                                    else
                                      onChangeScheduleItem(value, 'description')
                                  }}          
                                />
                            </Form.Group>
                            <Form.Group>
                                <label>Video Link</label>
                                <Form.Control
                                    value={scheduleItems[activeItem.value]?.videoLink || ''}
                                    placeholder="Video Link"
                                    type="string"
                                    onChange={event => onChangeScheduleItem(event.target.value, 'videoLink')}
                                />
                            </Form.Group>
                            <Form.Group>
                                <label>Lesson Text</label>
                                <ReactQuill 
                                  value={scheduleItems[activeItem.value]?.lessonText || ''}
                                  onChange={(value) => {
                                    if (value === '<p><br></p>')
                                      onChangeScheduleItem('', 'lessonText')
                                    else
                                      onChangeScheduleItem(value, 'lessonText')
                                  }}          
                                />
                            </Form.Group>
                            <Form.Group>
                              <label>Activities</label>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Activities"
                                name="multipleSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={(scheduleItems[activeItem.value]?.activities || []).map(activity => {
                                  const matchingOption = options.find(option => option.value === activity || option.value === activity.value);
                                  return matchingOption || { value: activity, label: activity.label || activity };
                                })}
                                onChange={(selectedOptions) => {
                                  // If selectedOptions is null or undefined, default to an empty array
                                  const activitiesValues = (selectedOptions || []).map(option => {
                                    return typeof option === 'object' ? option.value : option;
                                  });
                                
                                  onChangeScheduleItem(activitiesValues, 'activities');
                                }}
                                options={options} // Use the options array here
                              />
                            </Form.Group>
                          </>
                        )}
                    </Form>
                  </Modal.Body>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={handleClose}
                      variant="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={editChallengeId ? () => handleEditChallenge(editChallengeId) : handleAddChallenge}
                      variant="outlined"
                      color="primary"
                      disabled={!title || !description || !duration || !difficulty || !commitment || !imageLink}
                    >
                      Save
                    </Button>
                  </div>
                </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleClosePublish}
                  show={publishModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Publish Challenge</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to publish this Challenge? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleClosePublish}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handlePublishChallenge(publishChallengeId)}
                    // variant="danger"
                    color="primary"
                  >
                    Publish Challenge
                  </Button>
                </div>
              </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete Challenge</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Challenge? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteChallenge(deleteChallengeId)}
                    variant="danger"
                    color="primary"
                  >
                    Delete Challenge
                  </Button>
                </div>
              </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(ChallengeManager);
