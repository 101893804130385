import React, { useState } from "react";
// react component used to create charts
import SweetAlert from "react-bootstrap-sweetalert";
// react component that creates a form divided into multiple steps
import ReactWizard from "react-bootstrap-wizard";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import Step1 from "./Step1.js"
import Step2 from "./Step2.js";
import { useMutation } from "react-apollo";
import { ADD_APP_USER } from "views/mutations.js";
import { SUBSCRIBE_FEELWISE } from "views/mutations.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
// import Step3 from "./Step3.js";

function Wizard() {
  // const [alertState, setAlertState] = React.useState(false);
  const [error, setError] = useState()

  const steps = [
    { stepName: "Step 1: Tell us about yourself", component: Step1 },
    { stepName: "Step 2: Trial Details", component: Step2, stepProps: { error, } },
    // { stepName: "Complete", component: Step3 },
  ];

  const history = useHistory();

  const [addAppUser] = useMutation(ADD_APP_USER)
  const [subscribeFeelWise] = useMutation(SUBSCRIBE_FEELWISE)

  const searchParams = new URLSearchParams(window.location.search)
  const source = searchParams.get('source')

  return (
    <>
      <Container fluid style={{ marginTop: 25 }}>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <ReactWizard
              steps={steps}
              navSteps
              title={
                <img
                  alt="..."
                  //className="avatar border-gray"
                  style={{ width: '90%', maxWidth: 400, marginBottom: 20, marginTop: 20  }}
                  src={require("assets/img/feelwise-wordmark-icon.png")}
                />
              }
              // title="FeelWise Sign Up"
              // description="Sign up to FeelWise"
              headerTextCenter
              validate
              color="blue"
              previousButtonText="Back"
              nextButtonText="Go to step #2"
              finishButtonClasses="btn-info btn-wd"
              nextButtonClasses="btn-info btn-wd"
              previousButtonClasses="btn-wd"
              finishButtonText="Start 14 Day Free Trial"
              finishButtonClick={async (allStates) => {
                console.log(allStates)
                const {
                  fName,
                  lName,
                  email,
                  password,
                  birthday,
                  country,
                } = allStates["Step 1: Tell us about yourself"]
                const {
                  code,
                  validCode,
                  coupon,
                  validCoupon,
                  token,
                  selectedOption,
                } = allStates["Step 2: Trial Details"]
                try {
                  const userToken = await addAppUser({
                      variables: {
                          firstname: fName,
                          lastname: lName,
                          email,
                          password,
                          birthday,
                          country,
                          code: validCode ? code : '',
                          active: true,
                          app: 'feelwise',
                          status: 'active',
                          coupon: validCoupon ? coupon : '',
                          source,
                          subscribe: false
                      }
                  }).then(response => {
                      return response.data;
                  }).catch(err => {
                      throw err;
                  });
        
                  const userTokenData = userToken.addAppUser;
                  if (userTokenData.user) {
                      const { user } = userTokenData;
                      await subscribeFeelWise({
                          variables: {
                              app: 'feelwise',
                              subscribeInput: {
                                  userId: user._id,
                                  email,
                                  stripePlanId: selectedOption.planId,
                                  stripeToken: token,
                                  teamLimit: selectedOption.teamLimit
                              }
                          }
                      });
                      // Navigate to confirmation-page upon success
                      history.push('confirmation-page');
                  } else if (userTokenData.error) {
                      alert(userTokenData.error);
                  }
                } catch (error) {
                    // Assuming setError is a function to set an error state
                    setError(error.message);  // Make sure setError is defined
                    console.log(error);
                }
              }}
            />
          </Col>
        </Row>
      </Container>
      {/* {alertState} */}
    </>
  );
}

export default Wizard;
