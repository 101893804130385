import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import pullAt from 'lodash/pullAt'
import withSession from "./withSession";
import { ADD_HABIT_TO_CLIENT, UPDATE_HABIT, DELETE_HABIT } from "./mutations";

function HabitManager(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [description, setHabitDescription] = React.useState('')
    const [type, setType] = React.useState('')
    const [options, setOptions] = React.useState([])
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editHabitId, seteditHabitId] = React.useState('')
    const [deleteHabitId, setDeleteHabitId] = React.useState('')


    const [addHabitToClient] = useMutation(ADD_HABIT_TO_CLIENT)
    const [updateHabit] = useMutation(UPDATE_HABIT)
    const [deleteHabit] = useMutation(DELETE_HABIT)

    const addOption = () => {
      let newOptions = cloneDeep(options)
      newOptions.push('')
      setOptions(newOptions)
    }

    const changeOption = (value, index) => {
      let newOptions = cloneDeep(options)
      newOptions[index] = value
      setOptions(newOptions)
    }

    const removeOption = (index) => {
      let newOptions = cloneDeep(options)
      pullAt(newOptions, index)
      setOptions(newOptions)
    }

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setType('')
      setOptions([])
      setHabitDescription('')
      seteditHabitId('')
    }

    const handleOpenDelete = (habit) => {
      setDeleteModal(true)
      setDeleteHabitId(habit._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteHabitId('')
    }

    const handleAddHabit = () => {
      let habit = {
        title,
        type: type,
        description,
        options,
      }

      addHabitToClient({
        variables: {
          name: props.session.me.clientName,
          habit
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (habit) => {
      setModal(true)
      setTitle(habit.title)
      setType(habit.type)
      setHabitDescription(habit.description)
      setOptions(habit.options)
      seteditHabitId(habit._id)
      setShowError('')
    }

    const handleEditHabit = async (editHabitId) => {
      const habitUpdate = {
        title,
        type: type,
        description,
        options,
      }
      try {
        await updateHabit({
          variables: {
            habitId: editHabitId,
            name: props.session.me.clientName,
            habitUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating habit:', error);
      }
    }

    const handleDeleteHabit = async (deleteHabitId) => {
      try {
        await deleteHabit({
          variables: {
            habitId: deleteHabitId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating habit:', error);
      }
    }

    const typeOptions = [
     'Checkbox', 'Free Response', 'Free Response Long', 'Dropdown'
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { habits, adminUsers = [] } = getClient || {}
            
            const tableData = map(habits, (habit, key) => {
              return {
              id: key,
              ...habit,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(habit)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(habit)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Habit Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                  Header: "Type",
                                  accessor: "type",

                               },
                                {
                                    Header: "Description",
                                    accessor: "description",
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Habit</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                    >
                  <Modal.Header className="justify-content-center">
                    <label>{editHabitId ? 'Edit' : 'Add'} Habit</label>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#" method="#">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                value={title}
                                placeholder="Title"
                                type="string"
                                onChange={event => setTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Type</label>
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Type"
                                name="typeSelect"
                                value={{ value: type, label: type }}
                                getOptionValue={(option) => {
                                  return option.value
                                }}
                                onChange={(selectedOption) => {       
                                  setType(selectedOption.value);
                                }}
                                options={map(typeOptions, option => {
                                  return {
                                    label: option,
                                    value: option
                                  }
                                })} // Use the options array here
                              />
                        </Form.Group>
                        <Form.Group>
                            <label>Description</label>
                            <Form.Control
                                value={description}
                                placeholder="Description"
                                type="string"
                                onChange={event => setHabitDescription(event.target.value)}
                            />
                        </Form.Group>
                        {type === 'Dropdown' && (
                          <Form.Group>
                            <label>Options</label>
                            {map(options, (option, index) => (
                              <>
                                <Form.Control
                                  value={option}
                                  placeholder="Option"
                                  type="string"
                                  onChange={event => changeOption(event.target.value, index)}
                                />
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="text-danger btn-link edit"
                                  onClick={() => removeOption(index)}
                                >
                                  <i className="fa fa-trash" />
                                </Button>{" "}
                              </>
                            ))}
                            <Col xs="12">
                              <Button className="btn-simple" color="primary" onClick={addOption}>
                                Add Option
                              </Button>
                            </Col>
                          </Form.Group>
                        )}
                    </Form>
                  </Modal.Body>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={handleClose}
                      variant="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={editHabitId ? () => handleEditHabit(editHabitId) : handleAddHabit}
                      variant="outlined"
                      color="primary"
                      disabled={!title || !type || !description}
                    >
                      Save
                    </Button>
                  </div>
                </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete Habit</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Habit? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteHabit(deleteHabitId)}
                    variant="danger"
                    color="primary"
                  >
                    Delete Habit
                  </Button>
                </div>
              </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(HabitManager);
