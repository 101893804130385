import React, { useEffect, useState } from "react";
import Select from 'react-select';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from "views/PaymentForm";
import { useMutation, useLazyQuery } from "react-apollo";
import { ADD_APP_USER } from "views/mutations";
import { SUBSCRIBE_FEELWISE } from "views/mutations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CHECK_COUPON } from "views/queries";

import round from 'lodash/round'

import './datePicker.css'
import { VERIFY_CODE } from "views/queries";
import { useHistory, Link } from 'react-router-dom';
import { filter, includes } from "lodash";

const Step2 = React.forwardRef((props, ref) => {
    const isLocalHost = includes(process.env.REACT_APP_URI, 'localhost')

    let options = 
    isLocalHost ?
    [
      { value: 'monthlysingletest', label: 'The Buddy Plan ($9.95/mo)', planId: 'price_1P3jSyBKar2BpOhwIA9kSf1C', teamLimit: 1, price: 995, originalPrice: 29.95, discount: 20.00 },
      { value: 'yearlysingletest', label: 'The Buddy Plan ($99.95/yr)', planId: 'price_1P3jXJBKar2BpOhwIpn7UlC0', teamLimit: 1, price: 9995, originalPrice: 29.95, discount: 20.00 },
      { value: 'familymonthlytest', label: 'Friend and Family Shared Plan ($19.95/mo)', planId: 'price_1P3jZMBKar2BpOhwvdXDaFwm', teamLimit: 5, price: 1995, originalPrice: 49.95, discount: 30.00 },
      { value: 'yearlyfamilytest', label: 'Friend and Family Shared Plan ($199.00/yr)', planId: 'price_1P3jZpBKar2BpOhwDFi6du4L', teamLimit: 5, price: 19900, originalPrice: 588.00, discount: 389.00 },
    ]
    :
    [
      { value: 'monthlysingle', label: 'The Buddy Plan ($9.95/mo)', planId: 'price_1P6wHPBKar2BpOhwiiVjFs3A', teamLimit: 1, price: 995, originalPrice: 29.95, discount: 20.00 },
      { value: 'yearlysingle', label: 'The Buddy Plan ($99.95/yr)', planId: 'price_1P6wHRBKar2BpOhwS2akdJAE', teamLimit: 1, price: 9995, originalPrice: 29.95, discount: 20.00 },
      { value: 'familymonthly', label: 'Friend and Family Shared Plan ($19.95/mo)', planId: 'price_1P6wHfBKar2BpOhwzQGOGhxU', teamLimit: 5, price: 1995, originalPrice: 49.95, discount: 30.00 },
      { value: 'yearlyfamily', label: 'Friend and Family Shared Plan ($199.00/yr)', planId: 'price_1P6wHPBKar2BpOhwiiVjFs3A', teamLimit: 5, price: 19900, originalPrice: 49.95, discount: 30.00 },
    ];

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('defaultplan')) {
      if (searchParams.get('defaultplan') === 'buddy') {
        options = filter(options, option => {
          return includes(option.label, 'Buddy')
        })
      }
      if (searchParams.get('defaultplan') === 'shared') {
        options = filter(options, option => {
          return includes(option.label, 'Shared')
        })
      }
    }
    const defaultOption = searchParams.has('defaultplan') ? options[0] : { value: 'familymonthlytest', label: 'Friend and Family Shared Plan ($19.95/mo)', planId: isLocalHost ? 'price_1P3jZMBKar2BpOhwvdXDaFwm' : 'price_1P6wHfBKar2BpOhwzQGOGhxU', price: 1995 };
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    const [code, setCode] = useState()
    const [validCode, setValidCode] = useState(false)
    const [isChecked, setIsChecked] = useState(false);

    const [token, setToken] = useState('')
    const [resetToken, setResetToken] = useState(() => {
      return null
    })

    const [coupon, setCoupon] = useState()
    const [validCoupon, setValidCoupon] = useState(false)
    const [amountOff, setAmountOff] = useState()
    const [percentOff, setPercentOff] = useState()

    React.useImperativeHandle(ref, () => ({
      isValidated: isValidated,
      state: {
        code,
        validCode,
        coupon,
        validCoupon,
        token,
        selectedOption
      }
    }));

    function isValidated() {
      if (!isChecked) {
        alert("You need to agree to the terms of service to sign up")
        return false
      }
      return true
    }

    const handleChange = selectedOption => {
      setSelectedOption(selectedOption);
    };

    const [verifyCode] = useLazyQuery(VERIFY_CODE, {
      onCompleted: (data) => {
        console.log(data)
        if (data && data.verifyCode) {
          setValidCode(true)
        } else {
          setValidCode(false)
        }
      },
    });

    const [checkCoupon] = useLazyQuery(CHECK_COUPON, {
      onCompleted: (data) => {
        const { code, amountOff, percentOff } = data?.checkCoupon || {}
        if (code) {
          setValidCoupon(true)
          setAmountOff(amountOff)
          setPercentOff(percentOff)
        } else {
          setCoupon('')
          setValidCoupon(false)
          setAmountOff(0)
          setPercentOff(0)
        }
      },
    })

    const handleCode = (event) => {
      setCode(event.target.value)
      verifyCode({
        variables: {
          code: event.target.value,
        }
      })
    }

    const applyCoupon = (event) => {
      event.preventDefault();
      if (coupon)
        checkCoupon({
          variables: {
            code: coupon
          }
        })
    }

    const createToken = async (stripe, cardElement, nameOnCard) => {
      if (!nameOnCard) setToken('')
      else {
        await stripe
          .createToken(cardElement, {
            name: nameOnCard,
          })
          .then((res) => {
            var errorElement = document.getElementById('card-errors')
            if (res.error) {
              setToken('')
              if (errorElement)
                errorElement.textContent = res.error.message
            } else {
              setToken(res.token)
              if (errorElement) errorElement.textContent = null
            }
          })
      }
    }
    
    const [stripeResolved, setStripeResolved] = useState(false)
    const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
    const stripePromise = loadStripe(stripePublicKey)
    stripePromise.then(() => {
      setStripeResolved(true)
    })
  
  const checkboxStyle = {
    marginRight: '10px',
    verticalAlign: 'middle',
    cursor: 'pointer',
    width: '20px',    // Set the width of the checkbox
    height: '20px',   // Set the height of the checkbox
    backgroundColor: isChecked ? '#00B4BC' : '#fff', // Change background color when checked
    borderColor: '#ccc', // Border color
    position: 'relative', // For positioning the checkmark
};

  return (
    <div data-image={require("assets/img/FeelWiseMockup2.png")}>
      <div className="content d-flex align-items-center">
        <Container>
          <Card className="card-register card-plain text-center" style={{ marginTop: -40 }}>
            <Card.Body>
              <Row>
                <Col className="ml-auto" lg="6">
                  <div className="trial-info" style={{ background: '#fff', padding: '10px', borderRadius: '8px' }}>
                    <span className="trial-text" style={{ fontWeight: '600', fontSize: 24, color: '#00B4BC' }}>Start Your 14 Day Trial!</span>
                    {/* <span className="due-today" style={{ display: 'block', marginTop: '10px' }}>Total due today <strong>$0.00</strong></span> */}
                    <span style={{ display: 'block', marginTop: '0px', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>No charges will be processed today.</span>
                    <span style={{ display: 'block', marginTop: '10px', marginBottom: '10px', fontSize: 13 }}>After 14 days, we'll process a charge to your card on file, unless you decide to cancel prior to the end of your 14 Day Trial. Our Payments are 100% Safe & Secure.</span>
                  </div>
                  <Card className="card-plain">
                    {/* <div className="card-body">
                      <Form.Group>
                        <Form.Control
                          placeholder="Have a code?"
                          value={code}
                          onChange={handleCode}
                          style={{
                            borderColor: 'black',
                            color: 'black',
                            // height: 48,
                            borderWidth: 1,
                            padding: 10,
                            borderRadius: 50,
                          }}
                          type="text"
                        >
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="Have a coupon?"
                          value={coupon}
                          onChange={event => setCoupon(event.target.value)}
                          style={{
                            borderColor: 'black',
                            color: 'black',
                            borderWidth: 1,
                            padding: 10,
                            borderRadius: 50,
                          }}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </div> */}
                    {validCoupon && (
                      <>
                        <div className="text-center">
                          Original Price: ${Number(selectedOption.price)/100}
                        </div>
                        <div className="text-center">
                          Discount: ${round((selectedOption.price * (Number(percentOff) / 100) + Number(amountOff))/100, 2)}
                        </div>
                        <div className="text-center">
                          New Price: ${round((selectedOption.price * ((100 - Number(percentOff)) / 100) - Number(amountOff))/100, 2)}
                        </div>
                      </>
                    )}
                    
                    {/* <div className="text-center">
                      <Button
                        className="btn-fill btn-neutral btn-wd"
                        variant="default"
                        onClick={applyCoupon}
                        style={{ borderColor: 'black', borderWidth: 1, borderStyle: 'solid', borderRadius: 20, color: 'black'}}
                      >
                        Apply
                      </Button>
                    </div> */}
                  </Card>
                  {validCode ? (
                    <div className="text-center" style={{ color: 'green', marginBottom: 5 }}>
                      Team Invite Code Verified
                    </div>
                  ) : (!stripeResolved) ? (
                    <div className="text-center" style={{ color: 'green', marginBottom: 5 }}>
                      Loading Stripe
                    </div>
                  ) : (
                    <>
                      <Select
                          classNamePrefix="react-select"
                          placeholder="Choose Content"
                          name="contentDropdown"
                          closeMenuOnSelect={true}
                          value={selectedOption} // Controlled by the selectedOption state
                          onChange={handleChange}
                          options={options}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              borderColor: 'black',
                              color: 'black',
                              height: 48,
                              borderWidth: 1,
                              // padding: 10,
                              borderRadius: 80,
                              marginBottom: 30,
                              marginTop: 15,
                            }),
                            // input: (styles) => ({
                            //   ...styles,
                            //   marginBottom: -10,
                            // })
                          }}
                      />
                      <Elements
                        stripe={stripePromise}
                      >
                        <PaymentForm
                          createToken={
                            createToken
                          }
                          //setElement={setElement}
                          setResetToken={
                            setResetToken
                          }
                        />
                      </Elements>
                    </>
                  )}
                  <div className="form-group" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      id="termsAgreement"
                      name="termsAgreement"
                      style={checkboxStyle}     
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}                     
                      />
                    <label htmlFor="termsAgreement" style={{ fontWeight: 'normal', display: 'inline' }}>
                      I agree to the <Link to="/terms-of-use" target="_blank">Terms of Service</Link> and
                      <Link to="/privacy-policy" target="_blank"> Privacy Policy</Link>
                    </label>
                  </div>
                  {props.error && (
                    <div className="text-center" style={{ color: 'red', marginBottom: 5 }}>
                      {props.error}
                    </div>
                  )}
                  <Card.Title
                    style={{
                      color: 'red',
                      fontSize: 13,
                      marginTop: 3,
                      marginLeft: 12,
                      fontWeight: 400,
                    }}
                    id="card-errors"
                    role="alert"
                  />
                </Col>
                <Col className="mr-auto ml-auto" lg="6">
                  <Card className="card-plain">
                    <div className="trial-info" style={{ textAlign: 'start', background: '#fff', padding: '10px', borderRadius: '8px', alignItems: 'center' }}>
                      <span className="trial-text" style={{ fontWeight: '600', fontSize: 24, color: '#00B4BC' }}>Order Summary:</span>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* <span style={{ fontWeight: '500', fontSize: 14 }}>Friends and Family Shared Plan</span> */}
                        <span style={{ fontWeight: '500', fontSize: 14 }}>{selectedOption.label}</span>
                        <span style={{ fontWeight: '500', fontSize: 14, textDecoration: 'line-through' }}>${selectedOption.originalPrice}</span>
                      </div>  
                      <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomStyle: 'solid' }}>
                        <span style={{ fontWeight: '500', fontSize: 14 }}>Limited Time Discount</span>
                        <span style={{ fontWeight: '500', fontSize: 14 }}>-${selectedOption.discount}</span>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: '500', fontSize: 14 }}>Price after 14 day FREE trial:</span>
                        <span style={{ fontWeight: '500', fontSize: 14 }}>${Number(selectedOption.price)/100}</span>
                      </div>
                    </div>
                    <div>
                    <img src={require("assets/img/powered-by-stripe.png")} style={{ width: '100%', maxWidth: 300 }} />
                  </div>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
      {/* <div
        className="full-page-background"
        style={{
          backgroundImage: "url(" + require("assets/img/FeelWiseMockup2.png") + ")"
        }}
      ></div> */}
    </div>
  );
})

export default Step2;
