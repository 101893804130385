import React, { useState } from "react";
import Select from 'react-select';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

import './toggle.css'

function ToggleSwitch() {
    const [isToggled, setIsToggled] = useState(false);
  
    const handleToggle = () => {
      setIsToggled(!isToggled);
    };
  
    return (
    <div>
        <label className="switch">
          <input type="checkbox" checked={isToggled} onChange={handleToggle} />
          <div className="slider"></div>
        </label>
        <p style={{ color: 'black' }}>{isToggled ? 'Annual' : 'Monthly'}</p>
      </div>
    );
  }

function CreateAccountPlan() {
    const defaultOption = { value: 'familyPlanMonthly', label: 'Shared Plan ($19.95/mo)' };
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    const options = [
        { value: 'singlePlanMonthly', label: 'Buddy Plan ($9.95/mo)' },
        { value: 'familyPlanMonthly', label: 'Shared Plan ($19.95/mo)' },
        { value: 'singlePlanYearly', label: 'Buddy Plan ($99.95/yr)' },
        { value: 'familyPlanYearly', label: 'Shared Plan ($199.00/yr)' },
      ];

      const handleChange = selectedOption => {
        setSelectedOption(selectedOption);
      };

  return (
    <>
      <div
        className="full-page register-page section-image"
        // data-color="orange"
        // data-image={require("assets/img/full-screen-image-1.jpg")}
        // data-image={require("assets/img/bg10.jpg")}
      >
        <div className="content d-flex align-items-center">
          <Container>
            <Card className="card-register card-plain text-center">
              <Card.Header>
                <Row className="justify-content-center">
                  <Col md="8">
                    <div className="header-text">
                      <Card.Title as="h2">
                        <img
                          alt="..."
                          //className="avatar border-gray"
                          style={{ width: 500 }}
                          src={require("assets/img/feelwise-wordmark-icon.png")}
                        />
                      </Card.Title>
                      <Card.Subtitle as="h4" style={{ color: 'black' }}>
                        Select the plan that's right for you!
                      </Card.Subtitle>
                      <hr></hr>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="mr-auto ml-auto" md="5" lg="4">
                    <Form action="#" method="#">
                      <Card className="card-plain">
                        <div>
                            {/* <ToggleSwitch /> */}
                            {/* <h3 className="title">Content Page</h3> */}
                            <Select
                                // className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Content"
                                name="contentDropdown"
                                closeMenuOnSelect={true}
                                value={selectedOption} // Controlled by the selectedOption state
                                onChange={handleChange}
                                options={options}
                                style={{ 
                                    borderColor: 'black',
                                    color: 'black',
                                    height: 48,
                                    borderWidth: 1,
                                    padding: 10,
                                    borderRadius: 80,
                                }}
                            />
                        </div>
                        <div className="card-body">
                        <Form>
                            {/* <div> */}
                                <Form.Group>
                                    <Form.Control
                                        placeholder="Credit Card Number"
                                        style={{
                                        borderColor: 'black',
                                        color: 'black',
                                        // height: 48,
                                        borderWidth: 1,
                                        padding: 10,
                                        borderRadius: 50
                                        }}
                                        //   type="password"
                                    />
                                </Form.Group>
                            {/* </div> */}
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                                <Form.Group>
                                <Form.Control
                                    placeholder="Exp. Date"
                                    style={{
                                    borderColor: 'black',
                                    color: 'black',
                                    // height: 48,
                                    borderWidth: 1,
                                    padding: 10,
                                    marginRight: 5,
                                    borderRadius: 50
                                    }}
                                    type="select"
                                />
                                </Form.Group>
                                <Form.Group>
                                <Form.Control
                                    placeholder="CVV"
                                    style={{
                                    borderColor: 'black',
                                    color: 'black',
                                    // height: 48,
                                    borderWidth: 1,
                                    padding: 10,
                                    borderRadius: 50
                                    }}
                                    //   type="password"
                                />
                                </Form.Group>
                            {/* </div> */}
                        </Form>
                          {/* <Form.Group>
                            <Form.Control
                              placeholder="Credit Card Number"
                              style={{
                                borderColor: 'black',
                                color: 'black',
                                height: 48,
                                borderWidth: 1,
                                padding: 10,
                                borderRadius: 10
                                }}
                            ></Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              placeholder="Exp Date"
                              style={{
                                borderColor: 'black',
                                color: 'black',
                                height: 48,
                                borderWidth: 1,
                                padding: 10,
                                borderRadius: 10
                              }}
                            ></Form.Control>
                          </Form.Group> */}
                        </div>
                        <div style={{  display: 'flex', justifyContent: 'space-between' }}>
                            <div className="card-footer text-center">
                            <Button
                                className="btn-fill btn-neutral btn-wd"
                                type="submit"
                                variant="default"
                                style={{ borderColor: 'black', borderWidth: 1, borderStyle: 'solid', borderRadius: 20, color: 'black'}}
                            >
                                Back
                            </Button>
                            </div>
                            <div className="card-footer text-center">
                            <Button
                                className="btn-fill btn-neutral btn-wd"
                                type="submit"
                                variant="default"
                                style={{ borderColor: 'black', borderWidth: 1, borderStyle: 'solid', borderRadius: 20, color: 'black'}}
                            >
                                submit
                            </Button>
                            </div>
                        </div>
                      </Card>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
        {/* <div
          className="full-page-background"
          style={{
            backgroundImage: "url(" + require("assets/img/bg10.jpg") + ")"
          }}
        ></div> */}
      </div>
    </>
  );
}

export default CreateAccountPlan;
