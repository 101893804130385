import React, { useEffect, useState } from "react";
import Select from 'react-select';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from "views/PaymentForm";
import { useMutation, useLazyQuery } from "react-apollo";
import { ADD_APP_USER } from "views/mutations";
import { SUBSCRIBE_FEELWISE } from "views/mutations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CHECK_COUPON } from "views/queries";

import round from 'lodash/round'

import './datePicker.css'
import { VERIFY_CODE } from "views/queries";
import { useHistory, Link } from 'react-router-dom';
import { includes } from "lodash";
import Wizard from "views/Forms/SignUpWizard/Wizard";


export const countries = [
  "United States",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  // "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]

function CreateAccountPage() {
    const isLocalHost = includes(process.env.REACT_APP_URI, 'localhost')

    const defaultOption = { value: 'singlePlanMonthly', label: 'Shared Plan ($19.95/mo)', planId: isLocalHost ? 'price_1P3jZMBKar2BpOhwvdXDaFwm' : 'price_1P6wHfBKar2BpOhwzQGOGhxU', price: 1995 };
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    const [error, setError] = useState()
    const history = useHistory();

    const options = 
    isLocalHost ?
    [
      { value: 'monthlysingletest', label: 'Buddy Plan ($9.95/mo)', planId: 'price_1P3jSyBKar2BpOhwIA9kSf1C', teamLimit: 1, price: 995 },
      { value: 'familymonthlytest', label: 'Shared Plan ($19.95/mo)', planId: 'price_1P3jZMBKar2BpOhwvdXDaFwm', teamLimit: 5, price: 1995 },
      { value: 'yearlysingletest', label: 'Buddy Plan ($99.95/yr)', planId: 'price_1P3jXJBKar2BpOhwIpn7UlC0', teamLimit: 1, price: 9995 },
      { value: 'yearlyfamilytest', label: 'Shared Plan ($199.00/yr)', planId: 'price_1P3jZpBKar2BpOhwDFi6du4L', teamLimit: 5, price: 19900 },
    ]
    :
    [
      { value: 'monthlysingle', label: 'Buddy Plan ($9.95/mo)', planId: 'price_1P6wHPBKar2BpOhwiiVjFs3A', teamLimit: 1, price: 995 },
      { value: 'familymonthly', label: 'Shared Plan ($19.95/mo)', planId: 'price_1P6wHfBKar2BpOhwzQGOGhxU', teamLimit: 5, price: 1995 },
      { value: 'yearlysingle', label: 'Buddy Plan ($99.95/yr)', planId: 'price_1P6wHRBKar2BpOhwS2akdJAE', teamLimit: 1, price: 9995 },
      { value: 'yearlyfamily', label: 'Shared Plan ($199.00/yr)', planId: 'price_1P6wHPBKar2BpOhwiiVjFs3A', teamLimit: 5, price: 19900 },
    ];

    const handleChange = selectedOption => {
      setSelectedOption(selectedOption);
    };

    const [fName, setFName] = useState()
    const [lName, setLName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [birthday, setBirthday] = useState()
    const [country, setCountry] = useState()
    const [code, setCode] = useState()
    const [validCode, setValidCode] = useState(false)
    const [isChecked, setIsChecked] = useState(false);

    const [token, setToken] = useState('')
    const [resetToken, setResetToken] = useState(() => {
      return null
    })

    const [coupon, setCoupon] = useState()
    const [validCoupon, setValidCoupon] = useState(false)
    const [amountOff, setAmountOff] = useState()
    const [percentOff, setPercentOff] = useState()

    const [verifyCode] = useLazyQuery(VERIFY_CODE, {
      onCompleted: (data) => {
        console.log(data)
        if (data && data.verifyCode) {
          setValidCode(true)
        } else {
          setValidCode(false)
        }
      },
    });

    const [checkCoupon] = useLazyQuery(CHECK_COUPON, {
      onCompleted: (data) => {
        const { code, amountOff, percentOff } = data?.checkCoupon || {}
        if (code) {
          setValidCoupon(true)
          setAmountOff(amountOff)
          setPercentOff(percentOff)
        } else {
          setCoupon('')
          setValidCoupon(false)
          setAmountOff(0)
          setPercentOff(0)
        }
      },
    })

    const [addAppUser] = useMutation(ADD_APP_USER)
    const [subscribeFeelWise] = useMutation(SUBSCRIBE_FEELWISE)

    const handleCode = (event) => {
      setCode(event.target.value)
      verifyCode({
        variables: {
          code: event.target.value,
        }
      })
    }

    const applyCoupon = (event) => {
      event.preventDefault();
      checkCoupon({
        variables: {
          code: coupon
        }
      })
    }

    const createToken = async (stripe, cardElement, nameOnCard) => {
      if (!nameOnCard) setToken('')
      else {
        await stripe
          .createToken(cardElement, {
            name: nameOnCard,
          })
          .then((res) => {
            var errorElement = document.getElementById('card-errors')
            if (res.error) {
              setToken('')
              if (errorElement)
                errorElement.textContent = res.error.message
            } else {
              setToken(res.token)
              if (errorElement) errorElement.textContent = null
            }
          })
      }
    }
    
    const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
    const stripePromise = loadStripe(stripePublicKey)

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (fName === '' || lName === '' || email === '' || password === '' || confirmPassword === '' || country === '', !isChecked) {
          alert("All fields are required");
          return;
      } else if (password !== confirmPassword) {
          alert("Passwords don't match");
          return;
      }

      try {
          const userToken = await addAppUser({
              variables: {
                  firstname: fName,
                  lastname: lName,
                  email,
                  password,
                  birthday,
                  country,
                  code: validCode ? code : '',
                  active: true,
                  app: 'feelwise',
                  status: 'active',
                  coupon: validCoupon ? coupon : '',
              }
          }).then(response => {
              return response.data;
          }).catch(err => {
              throw err;
          });

          const userTokenData = userToken.addAppUser;
          if (userTokenData.user) {
              const { user } = userTokenData;
              await subscribeFeelWise({
                  variables: {
                      app: 'feelwise',
                      subscribeInput: {
                          userId: user._id,
                          email,
                          stripePlanId: selectedOption.planId,
                          stripeToken: token,
                          teamLimit: selectedOption.teamLimit
                      }
                  }
              });
              // Navigate to confirmation-page upon success
              history.push('confirmation-page');
          } else if (userTokenData.error) {
              alert(userTokenData.error);
          }
      } catch (error) {
          // Assuming setError is a function to set an error state
          setError(error.message);  // Make sure setError is defined
          console.log(error);
      }
  };
  
  const checkboxStyle = {
    marginRight: '10px',
    verticalAlign: 'middle',
    cursor: 'pointer',
    width: '20px',    // Set the width of the checkbox
    height: '20px',   // Set the height of the checkbox
    backgroundColor: isChecked ? '#00B4BC' : '#fff', // Change background color when checked
    borderColor: '#ccc', // Border color
    position: 'relative', // For positioning the checkmark
};

  return (
    <>
      <Wizard />
    </>
  );
}

export default CreateAccountPage;
