import gql from 'graphql-tag'

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($name: String!, $settingsInput: JSON!) {
    updateSettings(name: $name, settingsInput: $settingsInput) {
      _id
      name
      settings
    }
  }
`

export const SIGNIN = gql`
  mutation clientSignin($name: String!, $email: String!, $password: String!) {
    clientSignin(name: $name, email: $email, password: $password)
  }
`

export const UPDATE_ADMIN_USER = gql`
  mutation updateAdminUser($adminUserId: String!, $clientId: String!, $oldEmail: String!, $fullName: String!, $email: String!, $status: String!) {
    updateAdminUser(adminUserId: $adminUserId, clientId: $clientId, oldEmail: $oldEmail, fullName: $fullName, email: $email, status: $status) {
      _id
      name
      adminUsers {
        _id
        fullName
        email
        status
      }
    }
  }
`

export const ADD_ADMIN_USER = gql`
  mutation addAdminUser($clientId: String!, $fullName: String!, $email: String!, $status: String!, $password: String!) {
    addAdminUser(clientId: $clientId, fullName: $fullName, email: $email, status: $status, password: $password) {
      _id
      name
      adminUsers {
        _id
        fullName
        email
        status
      }
    }
  }
`

export const DELETE_ADMIN_USER = gql`
  mutation deleteAdminUser($adminUserId: String!, $clientId: String!, $email: String!) {
    deleteAdminUser(adminUserId: $adminUserId, clientId: $clientId, email: $email) {
      _id
      name
      adminUsers {
        _id
        fullName
        email
        status
      }
    }
  }
`

export const UPDATE_APP_USER = gql`
  mutation updateAppUser($oldEmail: String!, $email: String!, $active: Boolean!) {
    updateAppUser(oldEmail: $oldEmail, email: $email, active: $active) {
      _id
      firstname
      lastname
      email
      active
      type
    }
  }
`

export const ADD_APP_USER = gql`
  mutation addAppUser($firstname: String, $lastname: String, $email: String!, $status: String!, $password: String!, $active: Boolean!, $app: String, $birthday: DateTime, $code: String, $source: String, $subscribe: Boolean) {
    addAppUser(firstname: $firstname, lastname: $lastname, email: $email, status: $status, password: $password, active: $active, app: $app, birthday: $birthday, code: $code, source: $source, subscribe: $subscribe) {
      user {
        _id
        email
        firstname
        lastname
        active
        type
        isSubscribed
      }
      token
    }
  }
`

export const SUBSCRIBE_FEELWISE =gql`
  mutation subscribeFeelWise($app: String, $subscribeInput: SubscribeInput!) {
    subscribeFeelWise(app: $app, subscribeInput: $subscribeInput) {
      user {
        _id
        email
        active
        type
        isSubscribed
      }
      token
    }
  }
`

export const CHANGE_ADMIN_PASSWORD = gql`
  mutation changeAdminPassword($clientId: String!, $userEmail: String!, $adminUserId: String!, $password: String!) {
    changeAdminPassword(clientId: $clientId, userEmail: $userEmail, adminUserId: $adminUserId, password: $password) {
      _id
      name
      adminUsers {
        _id
        fullName
        email
        status
      }
    }
  }
`

export const CHANGE_APP_USER_PASSWORD = gql`
  mutation changeAppUserPassword($userId: String!, $password: String!) {
    changeAppUserPassword(userId: $userId, password: $password) {
      _id
      firstname
      lastname
      email
      password
      active
      type
      user_results
      user_journal
      createdAt
      shareAssessment
      appGuide
      isSubscribed
    }
  }
`

export const CHANGE_OPEN_AI_SYSTEM = gql`
  mutation changeOpenAISystem($clientId: String!, $openAISystem: String) {
    changeOpenAISystem(clientId: $clientId, openAISystem: $openAISystem) {
      _id
      name
      openAISystemMessage
    }
  }
`

export const DELETE_APP_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`

export const DELETE_APP_USER_SUBSCRIPTION = gql`
  mutation deleteAppUserSubscription($userId: String!) {
    deleteAppUserSubscription(userId: $userId)
  }
`

export const ADD_TUTORIAL_TO_CLIENT = gql`
  mutation AddTutorialToClient($name: String!, $tutorial: TutorialInput) {
    addTutorialToClient(name: $name, tutorial: $tutorial) {
      _id
      tutorials {
        _id
        page
        title
        videoURL
        description
        allowedSkips
      }
    }
  }
`;

export const UPDATE_TUTORIAL = gql`
  mutation UpdateTutorial($name: String!, $tutorialId: ID!, $tutorialUpdate: TutorialInput!) {
    updateTutorial(name: $name, tutorialId: $tutorialId, tutorialUpdate: $tutorialUpdate) {
      _id
      name
      tutorials {
        _id
        page
        title
        videoURL
        description
        allowedSkips
      }
    }
  }
`;

export const DELETE_TUTORIAL = gql`
  mutation DeleteTutorial($name: String!, $tutorialId: ID!) {
    deleteTutorial(name: $name, tutorialId: $tutorialId) {
      _id
      name
      tutorials {
        _id
        page
        title
        videoURL
        description
        allowedSkips
      }
    }
  }
`;

export const ADD_LESSON_TO_CLIENT = gql`
  mutation AddLessonToClient($name: String!, $lesson: LessonInput) {
    addLessonToClient(name: $name, lesson: $lesson) {
      _id
      lessons {
        title
        age
        lessonNumber
        videoLink
        activities
      }
    }
  }
`;

export const UPDATE_LESSON = gql`
  mutation UpdateLesson($name: String!, $lessonId: ID!, $lessonUpdate: LessonInput!) {
    updateLesson(name: $name, lessonId: $lessonId, lessonUpdate: $lessonUpdate) {
      _id
      name
      lessons {
        _id
        title
        age
        lessonNumber
        videoLink
        activities
      }
    }
  }
`;

export const DELETE_LESSON = gql`
  mutation DeleteLesson($name: String!, $lessonId: ID!) {
    deleteLesson(name: $name, lessonId: $lessonId) {
      _id
      name
      lessons {
        _id
        title
        age
        lessonNumber
        videoLink
        activities
      }
    }
  }
`;

export const ADD_COURSE_TO_CLIENT = gql`
  mutation AddCourseToClient($name: String!, $course: CourseInput) {
    addCourseToClient(name: $name, course: $course) {
      _id
      courses {
        title
        age
        description
        imageLink
        groups
        lessons {
          _id
          title
          age
          lessonNumber
          videoLink
          activities
        }
      }
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse($name: String!, $courseId: ID!, $courseUpdate: CourseInput!) {
    updateCourse(name: $name, courseId: $courseId, courseUpdate: $courseUpdate) {
      _id
      name
      courses {
        _id
        title
        age
        description
        imageLink
        groups
        lessons {
          _id
          title
          age
          lessonNumber
          videoLink
          activities
        }
      }
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($name: String!, $courseId: ID!) {
    deleteCourse(name: $name, courseId: $courseId) {
      _id
      name
      courses {
        _id
        title
        age
        description
        imageLink
        groups
        lessons {
          _id
          title
          age
          lessonNumber
          videoLink
          activities
        }
      }
    }
  }
`;


export const ADD_MEDITATION_TO_CLIENT = gql`
  mutation AddMeditationToClient($name: String!, $meditation: MeditationInput) {
    addMeditationToClient(name: $name, meditation: $meditation) {
      _id
      meditations {
        title
        age
        description
        audioLink
      }
    }
  }
`;

export const UPDATE_MEDITATION = gql`
  mutation UpdateMeditation($name: String!, $meditationId: ID!, $meditationUpdate: MeditationInput!) {
    updateMeditation(name: $name, meditationId: $meditationId, meditationUpdate: $meditationUpdate) {
      _id
      name
      meditations {
        _id
        title
        age
        description
        audioLink
      }
    }
  }
`;

export const DELETE_MEDITATION = gql`
  mutation DeleteMeditation($name: String!, $meditationId: ID!) {
    deleteMeditation(name: $name, meditationId: $meditationId) {
      _id
      name
      meditations {
        _id
        title
        age
        description
        audioLink
      }
    }
  }
`;


export const ADD_MOVEMENT_TO_CLIENT = gql`
  mutation AddMovementToClient($name: String!, $movement: MovementInput) {
    addMovementToClient(name: $name, movement: $movement) {
      _id
      movements {
        title
        age
        description
        videoLink
      }
    }
  }
`;

export const UPDATE_MOVEMENT = gql`
  mutation UpdateMovement($name: String!, $movementId: ID!, $movementUpdate: MovementInput!) {
    updateMovement(name: $name, movementId: $movementId, movementUpdate: $movementUpdate) {
      _id
      name
      movements {
        _id
        title
        age
        description
        videoLink
      }
    }
  }
`;

export const DELETE_MOVEMENT = gql`
  mutation DeleteMovement($name: String!, $movementId: ID!) {
    deleteMovement(name: $name, movementId: $movementId) {
      _id
      name
      movements {
        _id
        title
        age
        description
        videoLink
      }
    }
  }
`;

export const ADD_VISUALIZATION_TO_CLIENT = gql`
  mutation AddVisualizationToClient($name: String!, $visualization: VisualizationInput) {
    addVisualizationToClient(name: $name, visualization: $visualization) {
      _id
      visualizations {
        title
        age
        description
        audioLink
      }
    }
  }
`;

export const UPDATE_VISUALIZATION = gql`
  mutation UpdateVisualization($name: String!, $visualizationId: ID!, $visualizationUpdate: VisualizationInput!) {
    updateVisualization(name: $name, visualizationId: $visualizationId, visualizationUpdate: $visualizationUpdate) {
      _id
      name
      visualizations {
        _id
        title
        age
        description
        audioLink
      }
    }
  }
`;

export const DELETE_VISUALIZATION = gql`
  mutation DeleteVisualization($name: String!, $visualizationId: ID!) {
    deleteVisualization(name: $name, visualizationId: $visualizationId) {
      _id
      name
      visualizations {
        _id
        title
        age
        description
        audioLink
      }
    }
  }
`;

export const ADD_CHALLENGE_TO_CLIENT = gql`
  mutation AddChallengeToClient($name: String!, $challenge: ChallengeInput) {
    addChallengeToClient(name: $name, challenge: $challenge) {
      _id
      challenges {
        title
        description
        duration
        commitment
        imageLink
        schedule {
          title
          description
          videoLink
          lessonText
          activities
          startDay
          endDay
        }
      }
    }
  }
`;

export const UPDATE_CHALLENGE = gql`
  mutation UpdateChallenge($name: String!, $challengeId: ID!, $challengeUpdate: ChallengeInput!) {
    updateChallenge(name: $name, challengeId: $challengeId, challengeUpdate: $challengeUpdate) {
      _id
      name
      challenges {
        _id
        title
        description
        duration
        commitment
        imageLink
        schedule {
          title
          description
          videoLink
          lessonText
          activities
          startDay
          endDay
        }
      }
    }
  }
`;

export const PUBLISH_CHALLENGE = gql`
  mutation PublishChallenge($name: String!, $challengeId: ID!) {
    publishChallenge(name: $name, challengeId: $challengeId) {
      _id
      name
      challenges {
        _id
        title
        description
        duration
        commitment
        imageLink
        schedule {
          title
          description
          videoLink
          lessonText
          activities
          startDay
          endDay
        }
      }
    }
  }
`;

export const DELETE_CHALLENGE = gql`
  mutation DeleteChallenge($name: String!, $challengeId: ID!) {
    deleteChallenge(name: $name, challengeId: $challengeId) {
      _id
      name
      challenges {
        _id
        title
        description
        duration
        commitment
        imageLink
        schedule {
          title
          description
          videoLink
          lessonText
          activities
          startDay
          endDay
        }
      }
    }
  }
`;

export const ADD_HABIT_TO_CLIENT = gql`
  mutation AddHabitToClient($name: String!, $habit: HabitInput) {
    addHabitToClient(name: $name, habit: $habit) {
      _id
      habits {
        title
        description
        type
        options
      }
    }
  }
`;

export const UPDATE_HABIT = gql`
  mutation UpdateHabit($name: String!, $habitId: ID!, $habitUpdate: HabitInput!) {
    updateHabit(name: $name, habitId: $habitId, habitUpdate: $habitUpdate) {
      _id
      name
      habits {
        _id
        title
        description
        type
        options
      }
    }
  }
`;

export const DELETE_HABIT = gql`
  mutation DeleteHabit($name: String!, $habitId: ID!) {
    deleteHabit(name: $name, habitId: $habitId) {
      _id
      name
      habits {
        _id
        title
        description
        type
        options
      }
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  mutation uploadImage($fileUrl: String!, $documentId: String, $collection: String, $fileName: String) {
    uploadImage(fileUrl: $fileUrl, documentId: $documentId, collection: $collection, fileName: $fileName)
  }
`