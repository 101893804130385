import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_APP_USERS } from "./queries";

import map from "lodash/map";
import compact from "lodash/compact";
import last from "lodash/last";
import includes from "lodash/includes";
import withSession from "./withSession";
import toLower from "lodash/toLower";
import {
  ADD_APP_USER,
  CHANGE_APP_USER_PASSWORD,
  DELETE_APP_USER,
  DELETE_APP_USER_SUBSCRIPTION,
  UPDATE_APP_USER,
} from "./mutations";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { CSVLink } from "react-csv";

function AppUsers(props) {
  const [modal, setModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [modalPassword, setModalPassword] = React.useState(false);

  const [fullName, setFullName] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [status, setStatus] = React.useState("Active");
  const [active, setActive] = React.useState(true);
  const [gifts, setGifts] = React.useState(6);
  const [password, setPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [passwordMask, setPasswordMask] = React.useState(true);
  const [passwordMaskOld, setPasswordMaskOld] = React.useState(true);
  const [passwordMaskConfirm, setPasswordMaskConfirm] = React.useState(true);
  const [showError, setShowError] = React.useState("");
  const [showErrorConfirm, setShowErrorConfirm] = React.useState("");

  const [editEmail, setEditEmail] = React.useState("");

  const togglePasswordVisibility = () => {
    setPasswordMask(!passwordMask);
  };

  const togglePasswordVisibilityOld = () => {
    setPasswordMaskOld(!passwordMaskOld);
  };

  const togglePasswordVisibilityConfirm = () => {
    setPasswordMaskConfirm(!passwordMaskConfirm);
  };

  const handleActiveChange = (event) => {
    if (event.target.value === "yes") setActive(true);
    else setActive(false);
    // Additional logic on selection change can be added here
  };

  const [addAppUser] = useMutation(ADD_APP_USER);
  const [updateAppUser] = useMutation(UPDATE_APP_USER);
  const [changeAppUserPassword] = useMutation(CHANGE_APP_USER_PASSWORD);
  const [deleteAppUser] = useMutation(DELETE_APP_USER);
  const [deleteAppUserSubscription] = useMutation(DELETE_APP_USER_SUBSCRIPTION);

  const handleOpenEdit = (user) => {
    setModal(true);
    setUserId(user._id);
    setFullName(user.fullName);
    setEmail(user.email);
    setStatus(user.status);
    setActive(user.active || false);
    setEditEmail(user.email);
    setShowError("");
  };

  const handleOpenDelete = (user) => {
    setDeleteModal(true);
    setUserId(user._id);
  };

  const handleCloseDelete = (user) => {
    setDeleteModal(false);
    setUserId("");
  };

  const handleClose = () => {
    setModal(false);
    setFullName("");
    setEmail("");
    setStatus("Active");
    setActive(true);
    setPassword("");
    setPasswordMask(true);
    setEditEmail("");
  };

  const handleOpenPassword = () => {
    setModalPassword(true);
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
    setShowError("");
    setShowErrorConfirm("");
  };

  const handleClosePassword = () => {
    setModalPassword(false);
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
    setShowError("");
    setPasswordMask(true);
    setPasswordMaskOld(true);
    setPasswordMaskConfirm(true);
  };

  const handleAddUser = () => {
    addAppUser({
      variables: {
        email,
        status: "Admin Added",
        password,
        active,
        app: toLower(props.session.me.clientName),
        // gifts
      },
      refetchQueries: [
        {
          query: GET_APP_USERS,
          variables: {
            name: props.session.me.clientName,
          },
        },
      ],
    })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        setShowError(error.message);
      });
  };

  const handleEditUser = () => {
    updateAppUser({
      variables: {
        oldEmail: editEmail,
        email,
        active,
      },
      refetchQueries: [
        {
          query: GET_APP_USERS,
          variables: {
            name: props.session.me.clientName,
          },
        },
      ],
    })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        setShowError(error.message);
      });
  };

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      setShowErrorConfirm("Does not match password");
      return;
    }
    changeAppUserPassword({
      variables: {
        userId: userId,
        password,
      },
      refetchQueries: [
        {
          query: GET_APP_USERS,
          variables: {
            name: props.session.me.clientName,
          },
        },
      ],
    })
      .then(() => {
        handleClosePassword();
      })
      .catch((error) => {
        setShowError(error.message);
      });
  };

  const handleDeleteUser = () => {
    deleteAppUser({
      variables: {
        userId: userId,
      },
      refetchQueries: [
        {
          query: GET_APP_USERS,
          variables: {
            name: props.session.me.clientName,
          },
        },
      ],
    })
      .then(() => {
        deleteAppUserSubscription({
          variables: {
            userId: userId,
          },
        });
      })
      .then(() => {
        handleCloseDelete();
      })
      .catch((error) => {
        setShowError(error.message);
      });
  };

  const tableColumns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Subscription Type",
      accessor: "subscriptions.0.appType",
      Cell: ({ value }) => {
        if (!value) return "Internal - Unknown";
        return value;
      },
    },
    {
      Header: "Subscription Start",
      accessor: "subscriptions.0.start_date",
      // Cell: ({ value }) => {
      //   if (!value)
      //     return ''
      //   return new Date(value).toDateString()
      // }
    },
    {
      Header: "Subscription End",
      accessor: "subscriptions.0.end_date",
      // Cell: ({ value }) => {
      //   // Check if value is present and valid
      //   if (!value) {
      //     // Return a placeholder or an empty string if the value is missing or invalid
      //     return ''; // Or any other placeholder you prefer
      //   }

      //   // Assuming value is a valid date string, convert both dates to UTC strings for comparison
      //   const currentValueDate = new Date(value).toISOString();
      //   const currentDateUTCString = new Date().toISOString();

      //   // Check if the subscription has expired and display accordingly
      //   return currentValueDate < currentDateUTCString ? `${value} (expired)` : new Date(value).toDateString();
      // }
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      // Cell: ({ value }) => {
      //   if (!value)
      //     return ''
      //   return new Date(value).toDateString()
      // }
    },
    {
      Header: "Active",
      accessor: "active",
      // Cell: ({ value }) => (value ? 'Yes' : 'No')
    },
    // Conditionally add last rewire and last login if ultimind
    ...(props.session.me.clientName === "Ultimind"
      ? [
          {
            Header: "Last Rewire",
            accessor: "rewireChatDates",
            // Cell: ({ value }) => {
            //   if (!last(value)?.date)
            //     return ''
            //   return new Date(last(value)?.date).toDateString()
            // }
          },
          {
            Header: "Last Login",
            accessor: "dailyStreakDate",
            // Cell: ({ value }) => {
            //   if (!value)
            //     return ''
            //   return new Date(value).toDateString()
            // }
          },
        ]
      : []),
    // Conditionally add the "Gifts" column based on `app` being true
    ...(props.session.me.clientName === "feelwise" ||
    props.session.me.clientName === "regulate" ||
    props.session.me.clientName === "lumify"
      ? [
          {
            Header: "Team Leader",
            accessor: "teamLeader",
            // Cell: ({ value }) => {
            //   if (value?.firstname)
            //     return `${value.firstname} ${value.lastname}`
            //   else if (value?.email)
            //     return value.email
            //   return '-'
            // }
          },
        ]
      : []),
    ...(props.session.me.clientName === "feelwise" ||
    props.session.me.clientName === "regulate" ||
    props.session.me.clientName === "lumify"
      ? [
          {
            Header: "Type",
            accessor: "type",
          },
        ]
      : []),
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      skipCSVDownload: true,
    },
  ];

  return (
    <Query
      query={GET_APP_USERS}
      variables={{
        name: props.session.me.clientName,
      }}
    >
      {({ loading, data, refetch }) => {
        if (loading) return "Loading...";
        const tableData = map(data.getAppUsers, (user, key) => {
          const formatedSubscriptions = map(user.subscriptions, (sub) => {
            const { appType = "", product_id = "" } = sub || {};
            const type = appType ? appType : "Internal Account";
            const period = includes(product_id, "monthly")
              ? "Monthly"
              : includes(product_id, "yearly")
              ? "Yearly"
              : "Unknown";
            return {
              ...sub,
              start_date: !sub.start_date
                ? ""
                : new Date(sub.start_date).toLocaleDateString(),
              end_date: !sub.end_date
                ? ""
                : new Date(sub.end_date).toLocaleDateString(),
              appType: `${type} - ${period}`,
            };
          });
          return {
            id: key,
            ...user,
            active: user.active ? "Yes" : "No",
            subscriptions: formatedSubscriptions,
            createdAt: !user.createdAt
              ? ""
              : new Date(user.createdAt).toLocaleDateString(),
            rewireChatDates: !last(user.rewireChatDates)?.date
              ? ""
              : new Date(last(user.rewireChatDates).date).toLocaleDateString(),
            dailyStreakDate: !user.dailyStreakDate
              ? ""
              : new Date(user.dailyStreakDate).toLocaleDateString(),
            teamLeader: user.teamLeader?.firstname
              ? `${user.teamLeader.firstname} ${user.teamLeader.lastname}`
              : user.teamLeader?.email
              ? user.teamLeader.email
              : "",
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a edit kind of action */}
                {/* <Button
                      variant="info"
                      size="sm"
                      className="text-info btn-link edit"
                      onClick={() => handleOpenEdit(user)}
                    >
                      <i className="fa fa-info" />
                    </Button>{" "} */}
                <Button
                  variant="warning"
                  size="sm"
                  className="text-warning btn-link edit"
                  onClick={() => handleOpenEdit(user)}
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  variant="danger"
                  size="sm"
                  className="text-danger btn-link edit"
                  onClick={() => handleOpenDelete(user)}
                >
                  <i className="fa fa-trash" />
                </Button>{" "}
                {/* use this button to remove the data row */}
                {/* <Button
                      onClick={() => {
                        var newData = data;
                        newData.find((o, i) => {
                          if (o.id === key) {
                            newData.splice(i, 1);
                            return true;
                          }
                          return false;
                        });
                        setData([...newData]);
                      }}
                      variant="danger"
                      size="sm"
                      className="btn-link remove text-danger"
                    >
                      <i className="fa fa-times" />
                    </Button>{" "} */}
              </div>
            ),
          };
        });
        return (
          <>
            <Container fluid>
              <Row>
                <Col md="12">
                  <h4 className="title">App Users</h4>
                  {/* <p className="category">
                        A powerful react plugin handcrafted by our friends from{" "}
                        <a
                          href="https://react-table.tanstack.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          react-table
                        </a>
                        . It is a highly flexible tool, based upon the foundations of
                        progressive enhancement on which you can add advanced interaction
                        controls. Please check out their{" "}
                        <a
                          href="https://react-table.tanstack.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          full documentation.
                        </a>
                      </p> */}
                  <Card>
                    <Card.Body>
                      <ReactTable
                        data={tableData}
                        columns={tableColumns}
                        /*
                              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                            */
                        className="-striped -highlight primary-pagination"
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="6" style={{ marginTop: 25, textAlign: "left" }}>
                  <CSVLink
                    data={tableData}
                    headers={compact(
                      map(tableColumns, (column) => {
                        if (!column.skipCSVDownload)
                          return { label: column.Header, key: column.accessor };
                      })
                    )}
                  >
                    Download Users
                  </CSVLink>
                </Col>
                <Col xs="6" style={{ marginTop: 25, textAlign: "right" }}>
                  <Button
                    onClick={() => setModal(!modal)}
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 15,
                      paddingBottom: 15,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Add User
                  </Button>
                </Col>
              </Row>
            </Container>
            <Modal
              className="modal-primary"
              onHide={handleClose}
              show={modal}
              size="md"
            >
              <Modal.Header className="justify-content-center">
                <label>{!editEmail ? "Add User" : "Edit User"}</label>
              </Modal.Header>
              <Modal.Body>
                <Form action="#" method="#">
                  <Form.Group>
                    <label>Email address</label>
                    <Form.Control
                      value={email}
                      placeholder="Enter email"
                      type="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Form.Group>
                  {!editEmail && (
                    <Form.Group>
                      <label>Password</label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          isInvalid={showError}
                          type={passwordMask ? "password" : "text"}
                        ></Form.Control>
                        <InputGroup.Append>
                          <Button
                            variant="outline-secondary"
                            onClick={togglePasswordVisibility}
                            className={
                              passwordMask ? "text-primary" : "text-secondary"
                            }
                          >
                            {passwordMask ? <EyeSlash /> : <Eye />}
                          </Button>
                        </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                          {showError}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  )}
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label>Active</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={active ? "yes" : "no"}
                      onChange={handleActiveChange}
                    >
                      <option value="yes">Active - External</option>
                      <option value="no">Inactive - External</option>
                      {/* Add more options as needed */}
                    </Form.Control>
                  </Form.Group>
                  {(props.session.me.clientName === "feelwise" ||
                    props.session.me.clientName === "regulate" ||
                    props.session.me.clientName === "lumify") && (
                    <>
                      <Form.Group>
                        <label>Number of Gifts (including yourself)</label>
                        <Form.Control
                          value={gifts}
                          placeholder="6"
                          type="text"
                          onChange={(event) => setGifts(event.target.value)}
                        />
                      </Form.Group>
                    </>
                  )}
                </Form>
              </Modal.Body>
              <div className="modal-footer">
                <Button
                  className="btn-simple"
                  onClick={handleClose}
                  variant="link"
                >
                  Cancel
                </Button>
                {editEmail && (
                  <Button
                    className="btn-simple"
                    onClick={handleOpenPassword}
                    variant="link"
                  >
                    Reset Password
                  </Button>
                )}
                <Button
                  className="btn-simple"
                  onClick={editEmail ? handleEditUser : handleAddUser}
                  variant="outlined"
                  color="primary"
                  disabled={!email || !(editEmail || password) || !gifts}
                >
                  Save
                </Button>
              </div>
            </Modal>

            <Modal
              className="modal-primary"
              onHide={handleClosePassword}
              show={modalPassword}
              size="md"
            >
              <Modal.Header className="justify-content-center">
                <label>Reset Password</label>
              </Modal.Header>
              <Modal.Body>
                <Form action="#" method="#">
                  <Form.Group>
                    <label>Password</label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        type={passwordMask ? "password" : "text"}
                      ></Form.Control>
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          onClick={togglePasswordVisibility}
                          className={
                            passwordMask ? "text-primary" : "text-secondary"
                          }
                        >
                          {passwordMask ? <EyeSlash /> : <Eye />}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <label>Confirm Password</label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Password"
                        value={confirmPassword}
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                        isInvalid={showErrorConfirm}
                        type={passwordMaskConfirm ? "password" : "text"}
                      ></Form.Control>
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          onClick={togglePasswordVisibilityConfirm}
                          className={
                            passwordMaskConfirm
                              ? "text-primary"
                              : "text-secondary"
                          }
                        >
                          {passwordMaskConfirm ? <EyeSlash /> : <Eye />}
                        </Button>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {showErrorConfirm}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <div className="modal-footer">
                <Button
                  className="btn-simple"
                  onClick={handleClosePassword}
                  variant="link"
                >
                  Cancel
                </Button>
                <Button
                  className="btn-simple"
                  onClick={handleChangePassword}
                  variant="outlined"
                  color="primary"
                  disabled={!password || !confirmPassword}
                >
                  Save
                </Button>
              </div>
            </Modal>

            <Modal
              className="modal-primary"
              onHide={handleCloseDelete}
              show={deleteModal}
              size="md"
            >
              <Modal.Header className="justify-content-center">
                <label>Delete User</label>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this user? This action cannot be
                undone
              </Modal.Body>
              <div className="modal-footer">
                <Button
                  className="btn-simple"
                  onClick={handleCloseDelete}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  className="btn-simple"
                  onClick={handleDeleteUser}
                  variant="danger"
                  color="primary"
                >
                  Delete User
                </Button>
              </div>
            </Modal>
          </>
        );
      }}
    </Query>
  );
}

export default withSession(AppUsers);
