import React, { Component, useState } from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import map from "lodash/map";
import get from 'lodash/get'

import { Button, Card, Col, Form, FormGroup, FormControl, FormLabel, Row, Modal } from "react-bootstrap";

import './styles.css';
import { useMutation } from "react-apollo";
import { UPDATE_SETTINGS } from "views/mutations";
import { GET_SETTINGS } from "views/queries";
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import ReactPlayer from "react-player";
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import SweetAlert from "react-bootstrap-sweetalert";

const VideoCard = ({ videoUrl, title }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Button onClick={() => setShow(true)}>
      <i className="nc-icon nc-tv-2 icon-bold" style={{ marginRight: 10, }}></i>
        Watch
      </Button>
      <Modal 
        show={show}
        onHide={() => setShow(false)}
        size="md"
        dialogClassName="modal-dialog-centered"
        closeButton
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer height={300} width={450} url={videoUrl} controls={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const Step4 = React.forwardRef((props, ref) => {

  const { name, settings = {}, settingData = {} } = props

  const [showEdit, setShowEdit] = useState(false)
  const [openEdit, setOpenEdit] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [action, setAction] = useState('')
  const [content, setContent] = useState([''])

  const [alertState, setAlertState] = useState(null)

  const addNewContent = () => {
    let newContent = [...content]
    newContent.push('')
    setContent(newContent)
  }

  const handleSetContent = (value, index) => {
    let newContent = [...content]
    newContent[index] = value
    setContent(newContent)
  }

  const handleOpenEdit = (index) => {
    if (index < 0) {
      setTitle('')
      setDescription('')
      setAction('sit')
      setContent([''])
      setOpenEdit(index)
    } else {
      const editTarget = settingData.products[index] || {}
      setTitle(editTarget.title)
      setDescription(editTarget.description)
      setAction(editTarget.action)
      setContent(!isEmpty(editTarget.content) ? editTarget.content : [''])
      setOpenEdit(index)
    }
  }

  const handleCloseEdit = () => {
    setTitle('')
    setDescription('')
    setAction('')
    setContent('')
    setOpenEdit(null)
  }

  const [updateSettings] = useMutation(UPDATE_SETTINGS)

  const handleSubmit = async (event) => {
    let newSettings = cloneDeep(settings)
    const newValue = {
      title,
      description,
      action,
      content: compact(content),
    }
    newSettings[name].products.push(newValue)
    if (filter(newSettings[name].products, product => product.action === action).length > 1) {
      setAlertState('Action: ' + (action === 'sit' ? "Sit with it" : action === 'work' ? "Work through it" : action === 'learn' ? "Learn about it" : '') + ", is already being used for an product")
      return
    }
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
    handleCloseEdit()
  }

  const handleSubmitEdit = async (event) => {
    let newSettings = cloneDeep(settings)
    const newValue = {
      title,
      description,
      action,
      content: compact(content),
    }
    newSettings[name].products[openEdit] = newValue
    if (filter(newSettings[name].products, product => product.action === action).length > 1) {
      setAlertState('Action: ' + (action === 'sit' ? "Sit with it" : action === 'work' ? "Work through it" : action === 'learn' ? "Learn about it" : '') + ", is already being used for an product")
      return
    }
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
    handleCloseEdit()
  }

  const SortableItem = SortableElement(({value}) => (
    <>
    <Row>
      <Col xs="12">
        <Row>
          <Col>
            <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
              <h3>Product 1</h3>
              <Button style={{ borderRadius: 50, height: 35, width: 35, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                <i className="nc-icon nc-zoom-split" style={{ marginLeft: -5, marginTop: 2, color: 'black' }}></i>
              </Button>
              <Button style={{ borderRadius: 50, height: 35, width: 35, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                {/* <Row> */}
                  <Col style={{ marginBottom: -15, marginLeft: -21, marginTop: -3 }}>
                    <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                  </Col>
                  <Col style={{ marginBottom: -15, marginLeft: -21, marginTop: -10 }}>
                    <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                  </Col>
                  <Col style={{ marginLeft: -21 }}>
                    <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                  </Col>
                {/* </Row> */}
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      </Col>
      <Col xs="12">
        <div>
          {/* <VideoCard videoUrl={videoUrl} title={title} /> */}
        </div>
      </Col>
    </Row>
  </>
  ));

  const SortableList = SortableContainer(({items, collection}) => {
    // const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
    return (
      <Row>
        {/* {map(items, (value, index) => ( */}
          <SortableItem /*key={`item-${value}`} index={index} value={value} collection={collection}*/ />
        {/* ))} */}
      </Row>
    );
  });

  return (
    <div className="wizard-step" ref={ref}>
        {map(settingData.products, (product, index) => (
          <>
            <Row className="align-items-center">
              <Col xs="10">
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <h3>{product.title}</h3>
                </Row>
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <p>{product.description}</p>
                </Row>
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <p>{product.action === 'sit' ? "Sit with it" : product.action === 'work' ? "Work through it" : product.action === 'learn' ? "Learn about it" : ''}</p>
                </Row>
                {map(product.content, content => (
                  <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                    <div>
                      {ReactPlayer.canPlay(content) ? <VideoCard videoUrl={content} title={product.title} /> : <Button href={content} target="_blank"><i className="nc-icon nc-single-copy-04 icon-bold" style={{ marginRight: 10, }}></i>View</Button>}
                    </div>
                  </Row>
                ))}
              </Col>
              <Col >
                <Button onClick={() => handleOpenEdit(index)} style={{ borderRadius: 50, height: 50, width: 50, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                  <i className="fas fa-pen" style={{ marginLeft: -0, fontSize: 20, marginTop: 2, color: 'white' }}></i>
                </Button>
              </Col>
            </Row>
            <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
          </>
        ))}
        <Row>
          <Col xs="12">
            <Button
              className="btn-wd btn-outline mr-1"
              type="button"
              variant="info"
              style={{ width: '100%', height: '80px', fontSize: 24 }}
              onClick={() => handleOpenEdit(-1)}
            >
              <span className="btn-label" style={{ marginRight: 10}}>
                <i className="fas fa-plus"></i>
              </span>
              Add Product
            </Button>
          </Col>
        </Row>
        <Modal
          show={!isNil(openEdit)}
          onHide={handleCloseEdit}
          size='xl'
          dialogClassName="modal-dialog-centered"
          closeButton
          // contentClassName="modal-content"
          // style={{ height: '100%' }}
        >
          <Modal.Header closeButton>
              <Modal.Title as="h4">{openEdit >= 0 ? 'Edit' : 'Add'} Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col xs="12">
              <Form>
              <Form.Group className="mb-3" controlId="">
                  <Form.Label>Title</Form.Label>
                  <Form.Control as="textarea" value={title} onChange={event => setTitle(event.target.value)} rows={1} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" value={description} onChange={event => setDescription(event.target.value)} rows={10} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Action</Form.Label>
                  <Form.Control as="select" value={action} onChange={event => setAction(event.target.value)}>
                    <option selected value="sit">Sit with it</option>
                    <option value="work">Work through it</option>
                    <option value="learn">Learn about it</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    Content
                    <Button onClick={addNewContent} style={{ marginLeft: 10, borderRadius: 50, height: 35, width: 35 }}>
                      <i className="nc-icon nc-simple-add" style={{ fontWeight: 800, marginLeft: -6 }}></i>
                    </Button>
                  </Form.Label>
                  {map(content, (item, index) => (
                    <Form.Control as="textarea" style={{ marginBottom: 5 }} value={item} onChange={event => handleSetContent(event.target.value, index)} rows={1} />
                  ))}
                </Form.Group>
              </Form>
            </Col>
            <Col xs="12">
              <div className="text-right">
                <Button
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant="info"
                  onClick={openEdit >= 0 ? handleSubmitEdit : handleSubmit}
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  {openEdit >= 0 ? 'Edit' : 'Add'} Product
                </Button>
              </div>
            </Col>
          </Modal.Body>
          {alertState && (
            <SweetAlert
              error
              style={{ display: "block", marginTop: "-100px" }}
              title="Error"
              onConfirm={() => setAlertState(null)}
              onCancel={() => setAlertState(null)}
              confirmBtnBsStyle="info"
            >
              {alertState}
            </SweetAlert>
          )}
        </Modal>
        {/* <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
        <div style={{ padding: 25 }}>
          <SortableList items={items} helperClass={"sortableHelper"} />
        </div> */}
        {/* <Row>
          <Col xs="12">
            <Row>
              <Col>
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <h3>Product 1</h3>
                  <Button style={{ borderRadius: 50, height: 35, width: 35, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                    <i className="nc-icon nc-zoom-split" style={{ marginLeft: -5, marginTop: 2, color: 'black' }}></i>
                  </Button>
                  <Button style={{ borderRadius: 50, height: 35, width: 35, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                      <Col style={{ marginBottom: -15, marginLeft: -21, marginTop: -3 }}>
                        <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                      </Col>
                      <Col style={{ marginBottom: -15, marginLeft: -21, marginTop: -10 }}>
                        <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                      </Col>
                      <Col style={{ marginLeft: -21 }}>
                        <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                      </Col>
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </Col>
          <Col xs="12">
            <div>
            </div>
          </Col>
        </Row>
        <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
        <Row>
          <Col xs="12">
            <Row>
              <Col>
                <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                  <h3>Product 2</h3>
                  <Button style={{ borderRadius: 50, height: 35, width: 35, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                    <i className="nc-icon nc-zoom-split" style={{ marginLeft: -5, marginTop: 2, color: 'black' }}></i>
                  </Button>
                  <Button style={{ borderRadius: 50, height: 35, width: 35, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                      <Col style={{ marginBottom: -15, marginLeft: -21, marginTop: -3 }}>
                        <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                      </Col>
                      <Col style={{ marginBottom: -15, marginLeft: -21, marginTop: -10 }}>
                        <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                      </Col>
                      <Col style={{ marginLeft: -21 }}>
                        <i className="nc-icon nc-simple-delete" style={{ color: 'black' }}></i>
                      </Col>
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </Col>
          <Col xs="12">
            <div>
              <Card style={{ width: 350, height: 200 }}>
                
              </Card>
            </div>
          </Col>
        </Row> */}
    </div>
  );
});

export default Step4;
