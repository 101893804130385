import React, { useState } from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";
import ReactWizard from "react-bootstrap-wizard";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Dropdown,
    Form,
    InputGroup,
    Navbar,
    Nav,
    TabContent,
    TabPane,
    Container,
    Collapse,
    Modal,
    Row,
    Col,
    Tab,
  } from "react-bootstrap";
  
import Step1 from "./Forms/Wizard/Step1.js";
import Step2 from "./Forms/Wizard/Step2.js";
import Step3 from "./Forms/Wizard/Step3.js";
import Step4 from "./Forms/Wizard/Step4.js";
import Step5 from "./Forms/Wizard/Step5.js";
import { useQuery } from "react-apollo";
import { GET_SETTINGS } from "./queries.js";
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst'
import split from 'lodash/split'
import join from 'lodash/join'
import map from 'lodash/map'
import filter from 'lodash/filter'
import withSession from "./withSession.js";

const steps = [
  { stepName: "Description", component: Step1 },
  { stepName: "Explanation", component: Step2 },
  { stepName: "Experience", component: Step3 },
  { stepName: "Product", component: Step4 },
];

function Settings(props) {

const [selectedInfo, setSelectedInfo] = useState("EI Score");
const [show, setShow] = useState('');

const handleCardClick = (info) => {
  setSelectedInfo(info);
};

const handleClose = () => {
    setShow('');
}
const handleShow = (value, name) => {
    setShow(value);
}

const { loading, data, error} = useQuery(GET_SETTINGS, {
    variables: { name: props.session.me.clientName }
});

const { getSettings = {} } = data || {}

const settingData = get(getSettings, "settings." + show) || {}

  return (
    <>
      <Container fluid>
        <Row>
            <Col md="4">
                <Button color="primary" onClick={() => handleCardClick("EI Score")} style={{ width: '100%' }}>
                    <h5 style={{ fontSize: 20, fontWeight: 500, textAlign: 'center', margin: 5, }}>EI Score</h5>
                </Button>
            </Col>
            <Col md="4">
                <Button onClick={() => handleCardClick("Positive")} style={{ width: '100%' }}>
                    <h5 style={{ fontSize: 20, fontWeight: 500, textAlign: 'center', margin: 5, }}>Positive</h5>
                </Button>
            </Col>
            <Col md="4">
                <Button onClick={() => handleCardClick("Negative")} style={{ width: '100%' }}>
                    <h5 style={{ fontSize: 20, fontWeight: 500, textAlign: 'center', margin: 5, }}>Negative</h5>
                </Button>
            </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
            {selectedInfo === "EI Score" && (
                <Col xs="12">
                    <h2 style={{ marginBottom: 50 }}>EI Score</h2>
                    <ul className="list-inline" style={{ marginLeft: 50, marginRight: 50, marginTop: 25 }}>
                        {map(getSettings.settings, (value, key) => {
                            if (value.type !== "EI Score")
                                return null
                            return (
                                <>
                                    <li>
                                        <Row>
                                        <Col style={{ textAlign: 'left'}}>
                                            <h5>{value.name}</h5>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button style={{ marginLeft: 25 }} onClick={() => handleShow(key, value.name)}>
                                                <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                            </Button>
                                        </Col>
                                        </Row>
                                    </li>
                                    <Dropdown.Divider style={{ marginBottom: 30}} />
                                </>
                            )
                        })}
                        {/* <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Self-Awareness</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('self-awareness')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Self-Management</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('self-management')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Self-Expression</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('self-expression')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Empathy</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('empathy')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li> */}
                    </ul>
                </Col>
            )}
            {selectedInfo === "Positive" && (
                <Col xs="12">
                    <h2 style={{ marginBottom: 50 }}>Positive</h2>
                    <ul className="list-inline" style={{ marginLeft: 50, marginRight: 50, marginTop: 25 }}>
                        {map(getSettings.settings, (value, key) => {
                            if (value.type !== "Positive")
                                return null
                            return (
                                <>
                                    <li>
                                        <Row>
                                        <Col style={{ textAlign: 'left'}}>
                                            <h5>{value.name}</h5>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button style={{ marginLeft: 25 }} onClick={() => handleShow(key, value.name)}>
                                                <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                            </Button>
                                        </Col>
                                        </Row>
                                    </li>
                                    <Dropdown.Divider style={{ marginBottom: 30}} />
                                </>
                            )
                        })}
                        {/* <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Confidence</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('confidence')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Happiness</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('happiness')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Love</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('love')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Motivation</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('motivation')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Peace</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('peace')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Positive Stress</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('positive stress')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li> */}
                    </ul>
                </Col>
            )}
            {selectedInfo === "Negative" && (
                <Col xs="12">
                    <h2 style={{ marginBottom: 50 }}>Negative</h2>
                    <ul className="list-inline" style={{ marginLeft: 50, marginRight: 50, marginTop: 25 }}>
                        {map(getSettings.settings, (value, key) => {
                            if (value.type !== "Negative")
                                return null
                            return (
                                <>
                                    <li>
                                        <Row>
                                        <Col style={{ textAlign: 'left'}}>
                                            <h5>{value.name}</h5>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button style={{ marginLeft: 25 }} onClick={() => handleShow(key, value.name)}>
                                                <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                            </Button>
                                        </Col>
                                        </Row>
                                    </li>
                                    <Dropdown.Divider style={{ marginBottom: 30}} />
                                </>
                            )
                        })}
                        {/* <li>
                            <Row>
                                <Col style={{ textAlign: 'left'}}>
                                    <h5>Anger</h5>
                                </Col>
                                <Col style={{ textAlign: 'right' }}>
                                    <Button style={{ marginLeft: 25 }} onClick={() => handleShow('anger')}>
                                        <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Confusion</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('confusion')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Fear of Losing Identity</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('fear')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Loneliness</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('loneliness')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Sadness</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('sadness')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li>
                        <Dropdown.Divider style={{ marginBottom: 30}} />
                        <li>
                            <Row>
                            <Col style={{ textAlign: 'left'}}>
                                <h5>Stress</h5>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Button style={{ marginLeft: 25 }} onClick={() => handleShow('stress')}>
                                    <i className="nc-icon nc-settings-gear-64 icon-bold"></i>
                                </Button>
                            </Col>
                            </Row>
                        </li> */}
                    </ul>
                </Col>
            )}
        </Row>
      </Container>
      <Modal
        show={Boolean(show)}
        onHide={handleClose}
        size='xl'
        dialogClassName="modal-dialog-centered"
        closeButton
        // contentClassName="modal-content"
        // style={{ height: '100%' }}
      >
        <Modal.Header closeButton>
            {/* <Modal.Title as="h4" style={{ fontSize: 24, fontWeight: 500 }}>{join(map(split(join(map(split(show, '-'), upperFirst), '-'), ' '), upperFirst), ' ')}</Modal.Title> */}
            <Modal.Title as="h4" style={{ fontSize: 24, fontWeight: 500 }}>{settingData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tab.Container
            id="plain-tabs-example"
            defaultActiveKey="description-plain"
            >
            <Nav role="tablist" variant="tabs">
                <Nav.Item>
                <Nav.Link eventKey="description-plain">Description</Nav.Link>
                </Nav.Item>
                {settingData.explanations && (
                    <Nav.Item>
                    <Nav.Link eventKey="explanation-plain">Explanation</Nav.Link>
                    </Nav.Item>
                )}
                {settingData.experiences && (
                    <Nav.Item>
                     <Nav.Link eventKey="experience-plain">Experience</Nav.Link>
                    </Nav.Item>
                )}
                {settingData.products && (
                    <Nav.Item>
                    <Nav.Link eventKey="Product-plain">Product</Nav.Link>
                    </Nav.Item>
                )}
                {settingData.activities && (
                    <Nav.Item>
                    <Nav.Link eventKey="Product-plain">Activities</Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="description-plain">
                    <Step1 {...props} name={show} settingData={settingData} settings={getSettings.settings} />
                </Tab.Pane>
                {settingData.explanations && (
                     <Tab.Pane eventKey="explanation-plain">
                        <Step2 {...props} name={show} settingData={settingData} settings={getSettings.settings} />
                    </Tab.Pane>
                )}
                {settingData.experiences && (
                    <Tab.Pane eventKey="experience-plain">
                        <Step3 {...props} name={show} settingData={settingData} settings={getSettings.settings} />
                    </Tab.Pane>
                )}
                {settingData.products && (
                    <Tab.Pane eventKey="Product-plain">
                        <Step4 {...props} name={show} settingData={settingData} settings={getSettings.settings} />
                    </Tab.Pane>
                )}
                {settingData.activities && (
                     <Tab.Pane eventKey="Product-plain">
                        <Step5 {...props} name={show} settingData={settingData} settings={getSettings.settings} />
                    </Tab.Pane>
                )}
            </Tab.Content>
            </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withSession(Settings);