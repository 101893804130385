import React, { useState } from "react";

import ChartistGraph from "react-chartist";
import { Button,Card, Col, Form, Modal, Row } from "react-bootstrap";

import Slider from "nouislider-react";
import map from 'lodash/map'
import { useMutation } from "react-apollo";
import { UPDATE_SETTINGS } from "views/mutations";
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import head from 'lodash/head'
import last from 'lodash/last'
import isNil from 'lodash/isNil'
import { GET_SETTINGS } from "views/queries";

const Step2 = React.forwardRef((props, ref) => {
  const { name, settings = {}, settingData = {} } = props

  const [showEdit, setShowEdit] = useState(false)
  const [openEdit, setOpenEdit] = useState(null)
  const [scale, setScale] = useState([0, 0])
  const [explanation, setExplanation] = useState('')

  const handleOpenEdit = (index) => {
    if (index < 0) {
      setScale([0, 0])
      setExplanation('')
      setOpenEdit(index)
    } else {
      const editTarget = filter(settingData.explanations, 'active')[index] || {}
      setScale([editTarget.min, editTarget.max])
      setExplanation(editTarget.explanation)
      setOpenEdit(index)
    }
  }

  const handleCloseEdit = () => {
    setScale([0, 0])
    setExplanation('')
    setOpenEdit(null)
  }

  const [updateSettings] = useMutation(UPDATE_SETTINGS)
  
  const handleSubmit = async () => {
    let newSettings = cloneDeep(settings)
    const newValue = {
      min: parseInt(scale[0]),
      max: parseInt(scale[1]),
      explanation,
      active: true,
    }
    console.log("newSettings", newSettings[name])
    let newExplanations = cloneDeep(newSettings[name].explanations) || []
    map(filter(newExplanations, 'active'), explanation => {
      if (explanation.min >= newValue.min && explanation.max <= newValue.max) { //if fully encapsulated then mark the old one for removal
        explanation.active = false
      } else if (explanation.min < newValue.min && explanation.max >= newValue.min && explanation.max <= newValue.max) { //if max is encapsulated but not min move old max
        explanation.max = newValue.min - 1
      } else if (explanation.min >= newValue.min && explanation.min <= newValue.max && explanation.max > newValue.max) { //if min is encapsulated but not max move old min
        explanation.min = newValue.max + 1
      } else if (explanation.min < newValue.min && explanation.max > newValue.max) { //if new value is encapsulated then split the old
        const newLower = {
          min: explanation.min,
          max: newValue.min - 1,
          explanation: explanation.explanation,
          active: true,
        }
        const newUpper = {
          min: newValue.max + 1,
          max: explanation.max,
          explanation: explanation.explanation,
          active: true,
        }
        explanation.min = -1
        explanation.max = -1
        newExplanations.push(newLower)
        newExplanations.push(newUpper)
      }
    })
    newExplanations.push(newValue)
    //filter explanations so only ones with valid min and max are included
    newExplanations = filter(newExplanations, explanation => explanation.min >= 0 && explanation.max <= 100 && explanation.min <= explanation.max)
    newExplanations = sortBy(newExplanations, ['active', "min"])
    newSettings[name].explanations = newExplanations
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
    handleCloseEdit()
  }

  const handleSubmitEdit = async (event) => {
    let newSettings = cloneDeep(settings)
    const newValue = {
      min: parseInt(scale[0]),
      max: parseInt(scale[1]),
      explanation,
      active: true,
    }
    let newExplanations = cloneDeep(newSettings[name].explanations) || []
    let oldMin
    let oldMax
    map(filter(newExplanations, 'active'), (explanation, index) => {
      if (index == openEdit) { // Replace old version of edited field
        oldMin = explanation.min
        oldMax = explanation.max
        explanation.min = newValue.min
        explanation.max = newValue.max
        explanation.explanation = newValue.explanation
        explanation.active = true
      } else {
        if (explanation.min >= newValue.min && explanation.max <= newValue.max) { //if fully encapsulated then mark the old one for removal
          explanation.active = false
        } else if (explanation.min < newValue.min && explanation.max >= newValue.min && explanation.max <= newValue.max) { //if max is encapsulated but not min move old max
          explanation.max = newValue.min - 1
        } else if (explanation.min >= newValue.min && explanation.min <= newValue.max && explanation.max > newValue.max) { //if min is encapsulated but not max move old min
          explanation.min = newValue.max + 1
        } else if (explanation.min < newValue.min && explanation.max > newValue.max) { //if new value is encapsulated then split the old
          const newLower = {
            min: explanation.min,
            max: newValue.min - 1,
            explanation: explanation.explanation,
            active: true,
          }
          const newUpper = {
            min: newValue.max + 1,
            max: explanation.max,
            explanation: explanation.explanation,
            active: true,
          }
          explanation.min = -1
          explanation.max = -1
          newExplanations.push(newLower)
          newExplanations.push(newUpper)
        }
      }
    })
    //filter explanations so only ones with valid min and max are included
    newExplanations = filter(newExplanations, explanation => explanation.min >= 0 && explanation.max <= 100 && explanation.min <= explanation.max)
    newExplanations = sortBy(newExplanations, ['active', "min"])
    //Fill in gaps
    if (oldMin < newValue.min) {
      if (oldMin == 0) {
        newExplanations.push({
          min: 0,
          max: head(filter(newExplanations, 'active')).min - 1,
          explanation: '-',
          active: true
        })
      }
      else {
        const fillUpTarget = findIndex(newExplanations, { max: oldMin - 1, active: true })
        newExplanations[fillUpTarget].max = newExplanations[fillUpTarget + 1].min - 1
      }
    }
    if (oldMax > newValue.max) {
      if (oldMax == 100) {
        newExplanations.push({
          min: last(filter(newExplanations, 'active')).max + 1,
          max: 100,
          explanation: '-',
          active: true
        })
      }
      else {
        const fillDownTarget = findIndex(newExplanations, { min: oldMax + 1, active: true })
        newExplanations[fillDownTarget].min = newExplanations[fillDownTarget - 1].max + 1
      }
    }
    newExplanations = sortBy(newExplanations, ['active', "min"])
    newSettings[name].explanations = newExplanations
    await updateSettings({
      variables: {
        name: props.session.me.clientName,
        settingsInput: newSettings,
      },
      refetchQueries: [
        {
          query: GET_SETTINGS,
          variables: { name: props.session.me.clientName }
        }
      ]
    })
    handleCloseEdit()
  }

  return (
    <div className="wizard-step" ref={ref}>
      {map(filter(settingData.explanations, 'active'), (explanation, index) => (
        <>
          <Row className="align-items-center">
            <Col xs="10">
              <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                <h3>{explanation.min}-{explanation.max}</h3>
              </Row>
              <Row style={{ marginLeft: '0px', alignItems: 'center' }}>
                <p>{explanation.explanation}</p>
              </Row>
            </Col>
            <Col >
              <Button onClick={() => handleOpenEdit(index)} style={{ borderRadius: 50, height: 50, width: 50, marginLeft: 10, marginTop: 15, backgroundColor: 'lightGrey'  }}>
                <i className="fas fa-pen" style={{ marginLeft: -0, fontSize: 20, marginTop: 2, color: 'white' }}></i>
              </Button>
            </Col>
          </Row>
          <hr style={{ borderBottomStyle: 'solid', borderBottomWidth: '.05px', borderBottomColor: 'lightGrey'}} />
        </>
      ))}
      <Row>
        <Col xs="12">
          <div className="text-right">
            <Button
              className="btn-wd btn-outline mr-1"
              type="button"
              variant="info"
              style={{ width: '100%', height: '80px', fontSize: 24 }}
              onClick={() => handleOpenEdit(-1)}
            >
              <span className="btn-label" style={{ marginRight: 10}}>
                <i className="fas fa-plus"></i>
              </span>
              Add Explanation
            </Button>
          </div>
        </Col>
      </Row>
      <Modal
        show={!isNil(openEdit)}
        onHide={handleCloseEdit}
        size='xl'
        dialogClassName="modal-dialog-centered"
        closeButton
        // contentClassName="modal-content"
        // style={{ height: '100%' }}
      >
        <Modal.Header closeButton>
            <Modal.Title as="h4">{openEdit >= 0 ? 'Edit' : 'Add'} Explanation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col xs="12">
            <h4 className="title" style={{ marginBottom: 50 }}>Scale</h4>
            {/* <div className="slider-info" ref={slider1}></div>
            <br></br> */}
            <Slider range={{ min: 0, max: 100 }} value={scale} start={scale} connect={[false, true, false]} step={1} onChange={value => setScale(value)} tooltips />
            <Form>
              <Form.Group className="mb-3" controlId="">
                <Form.Label><h4 className="title">Explanation</h4></Form.Label>
                <Form.Control as="textarea" value={explanation} onChange={event => setExplanation(event.target.value)} rows={10} />
              </Form.Group>
            </Form>
          </Col>
          <Col xs="12">
            <div className="text-right">
              <Button
                className="btn-wd btn-outline mr-1"
                type="button"
                variant="info"
                onClick={openEdit >= 0 ? handleSubmitEdit : handleSubmit}
              >
                <span className="btn-label">
                  <i className="fas fa-plus"></i>
                </span>
                {openEdit >= 0 ? 'Edit' : 'Add'} Explanation
              </Button>
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default Step2;
