import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import withSession from "./withSession";
import { ADD_ADMIN_USER, CHANGE_ADMIN_PASSWORD, DELETE_ADMIN_USER, UPDATE_ADMIN_USER } from "./mutations";
import { Eye, EyeSlash } from "react-bootstrap-icons";

function Team(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [fullName, setFullName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [status, setStatus] = React.useState('Active')
    const [password, setPassword] = React.useState('')
    // const [oldPassword, setOldPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')

    const [passwordMask, setPasswordMask] = React.useState(true)
    const [passwordMaskOld, setPasswordMaskOld] = React.useState(true)
    const [passwordMaskConfirm, setPasswordMaskConfirm] = React.useState(true)
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editEmail, setEditEmail] = React.useState('')
    const [editAdminUserIdPassword, setEditAdminUserIdPassword] = React.useState('')
    const [editAdminUserId, setEditAdminUserId] = React.useState('')
    const [deleteAdminUserIdPassword, setDeleteAdminUserIdPassword] = React.useState('')


    const togglePasswordVisibility = () => {
      setPasswordMask(!passwordMask)
    }

    const togglePasswordVisibilityOld = () => {
      setPasswordMaskOld(!passwordMaskOld)
    }

    const togglePasswordVisibilityConfirm = () => {
      setPasswordMaskConfirm(!passwordMaskConfirm)
    }

    const [addAdminUser] = useMutation(ADD_ADMIN_USER)
    const [updateAdminUser] = useMutation(UPDATE_ADMIN_USER)
    const [deleteAdminUser] = useMutation(DELETE_ADMIN_USER)
    const [changeAdminPassword] = useMutation(CHANGE_ADMIN_PASSWORD)

    const handleOpenDelete = (user) => {
      setDeleteModal(true)
      setEmail(user.email)
      setDeleteAdminUserIdPassword(user._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setEmail('')
      setDeleteAdminUserIdPassword('')
    }

    const handleOpenEdit = (user) => {
      setModal(true)
      setFullName(user.fullName)
      setEmail(user.email)
      setStatus(user.status)
      // setPassword('')
      // setPasswordMask(true)
      setEditEmail(user.email)
      setShowError('')
      setEditAdminUserId(user._id)
      setEditAdminUserIdPassword(user._id)
    }

    const handleClose = () => {
      setModal(false)
      setFullName('')
      setEmail('')
      setStatus('Active')
      setPassword('')
      setPasswordMask(true)
      setEditEmail('')
      setEditAdminUserId('')
    }

    const handleOpenPassword = (adminUserId) => {
      setModalPassword(true)
      // setOldPassword('')
      setPassword('')
      setConfirmPassword('')
      setShowError('')
      setShowErrorConfirm('')
      setEditAdminUserIdPassword(adminUserId)
    }

    const handleClosePassword = () => {
      setModalPassword(false)
      // setOldPassword('')
      setPassword('')
      setConfirmPassword('')
      setShowError('')
      setPasswordMask(true)
      setPasswordMaskOld(true)
      setPasswordMaskConfirm(true)
      setEditAdminUserIdPassword('')
    }

    const handleAddUser = () => {
      addAdminUser({
        variables: {
          clientId: props.session.me.clientId,
          fullName,
          email,
          status,
          password
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleEditUser = () => {
      updateAdminUser({
        variables: {
          adminUserId: editAdminUserId,
          clientId: props.session.me.clientId,
          oldEmail: editEmail,
          fullName,
          email,
          status,
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleDeleteUser = (deleteAdminUserIdPassword) => {
      deleteAdminUser({
        variables: {
          clientId: props.session.me.clientId,
          adminUserId: deleteAdminUserIdPassword,
          email,
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleCloseDelete()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleChangePassword = () => {
      if (password !== confirmPassword) {
        setShowErrorConfirm("Does not match password")
        return
      }
      changeAdminPassword({
        variables: {
          clientId: props.session.me.clientId,
          userEmail: editEmail,
          adminUserId: editAdminUserIdPassword,
          // oldPassword,
          password,
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClosePassword()
      }).catch(error => {
        setShowError(error.message)
      })
    }

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { adminUsers = [] } = getClient || {}
            const tableData = map(adminUsers, (user, key) => {
              return {
                id: key,
                ...user,
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    {/* use this button to add a edit kind of action */}
                    <Button
                      variant="warning"
                      size="sm"
                      className="text-warning btn-link edit"
                      onClick={() => handleOpenEdit(user)}
                    >
                      <i className="fa fa-edit" />
                    </Button>{" "}
                    <Button
                      variant="danger"
                      size="sm"
                      className="text-danger btn-link edit"
                      onClick={() => handleOpenDelete(user)}
                    >
                      <i className="fa fa-trash" />
                    </Button>{" "}
                    {/* use this button to remove the data row */}
                    {/* <Button
                      onClick={() => {
                        var newData = data;
                        newData.find((o, i) => {
                          if (o.id === key) {
                            newData.splice(i, 1);
                            return true;
                          }
                          return false;
                        });
                        setData([...newData]);
                      }}
                      variant="danger"
                      size="sm"
                      className="btn-link remove text-danger"
                    >
                      <i className="fa fa-times" />
                    </Button>{" "} */}
                  </div>
                ),
              };
            })
            return (
              <>
                <Container fluid>
                  <Row>
                    <Col md="12">
                      <h4 className="title">Team Members</h4>
                      {/* <p className="category">
                        A powerful react plugin handcrafted by our friends from{" "}
                        <a
                          href="https://react-table.tanstack.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          react-table
                        </a>
                        . It is a highly flexible tool, based upon the foundations of
                        progressive enhancement on which you can add advanced interaction
                        controls. Please check out their{" "}
                        <a
                          href="https://react-table.tanstack.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          full documentation.
                        </a>
                      </p> */}
                      <Card>
                        <Card.Body>
                          <ReactTable
                            data={tableData}
                            columns={[
                              {
                                  Header: "Name",
                                  accessor: "fullName",
                                  sortable: false,
                                  filterable: false,
                              },
                              {
                                  Header: "Email",
                                  accessor: "email",
                              },
                              {
                                  Header: "Status",
                                  accessor: "status",
                              },
                              {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                            ]}
                            /*
                              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                            */
                            className="-striped -highlight primary-pagination"
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                      <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Team Member</Button>
                    </Col>
                  </Row>
                </Container>
                <Modal
                  className="modal-primary"
                  onHide={handleClose}
                  show={modal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Add User</label>
                </Modal.Header>
                <Modal.Body>
                  <Form action="#" method="#">
                      <Form.Group>
                          <label>Full Name</label>
                          <Form.Control
                              value={fullName}
                              placeholder="Full Name"
                              type="string"
                              onChange={event => setFullName(event.target.value)}
                          />
                      </Form.Group>
                      <Form.Group>
                          <label>Email address</label>
                          <Form.Control
                              value={email}
                              placeholder="Enter email"
                              type="email"
                              onChange={event => setEmail(event.target.value)}
                          />
                      </Form.Group>
                      <Form.Group>
                          <label>Status</label>
                          <Form.Control
                              value={status}
                              as="select"
                              onChange={event => setStatus(event.target.value)}
                          >
                            <option value="Active">Active</option>
                            <option value="Not Active">Not Active</option>
                          </Form.Control>
                      </Form.Group>
                      {!editEmail && (
                        <Form.Group>
                            <label>Password</label>
                            <InputGroup><Form.Control
                            placeholder="Password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            isInvalid={showError}
                            type={
                              passwordMask
                                ? 'password'
                                : 'text'
                            }
                          ></Form.Control>
                                  <InputGroup.Append>
                                  <Button variant="outline-secondary" onClick={togglePasswordVisibility} className={passwordMask ? "text-primary" : "text-secondary"}>
                                    {passwordMask ? <EyeSlash /> : <Eye />}
                                  </Button>
                                </InputGroup.Append>
                          <Form.Control.Feedback type="invalid">
                            {showError}
                          </Form.Control.Feedback></InputGroup>
                        </Form.Group>
                      )}
                      
                  </Form>
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleClose}
                    variant="link"
                  >
                    Cancel
                  </Button>
                  {editEmail && (
                    <Button
                      className="btn-simple"
                      onClick={() => handleOpenPassword(editAdminUserIdPassword)}
                      variant="link"
                    >
                      Reset Password
                    </Button>
                  )}
                  <Button
                    className="btn-simple"
                    onClick={editEmail ? handleEditUser : handleAddUser}
                    variant="outlined"
                    color="primary"
                    disabled={!fullName || !email || !status || !(editEmail || password)}
                  >
                    Save
                  </Button>
                </div>
              </Modal>

              <Modal
                  className="modal-primary"
                  onHide={handleClosePassword}
                  show={modalPassword}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Reset Password</label>
                </Modal.Header>
                <Modal.Body>
                  <Form action="#" method="#">
                    {/* <Form.Group>
                        <label>Old Password</label>
                        <InputGroup><Form.Control
                        placeholder="Password"
                        value={oldPassword}
                        onChange={event => setOldPassword(event.target.value)}
                        isInvalid={showError}
                        type={
                          passwordMaskOld
                            ? 'password'
                            : 'text'
                        }
                      ></Form.Control>
                              <InputGroup.Append>
                              <Button variant="outline-secondary" onClick={togglePasswordVisibilityOld} className={passwordMaskOld ? "text-primary" : "text-secondary"}>
                                {passwordMaskOld ? <EyeSlash /> : <Eye />}
                              </Button>
                            </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {showError}
                      </Form.Control.Feedback></InputGroup>
                    </Form.Group> */}
                    <Form.Group>
                          <label>Password</label>
                          <InputGroup><Form.Control
                          placeholder="Password"
                          value={password}
                          onChange={event => setPassword(event.target.value)}
                          type={
                            passwordMask
                              ? 'password'
                              : 'text'
                          }
                        ></Form.Control>
                                <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={togglePasswordVisibility} className={passwordMask ? "text-primary" : "text-secondary"}>
                                  {passwordMask ? <EyeSlash /> : <Eye />}
                                </Button>
                              </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    <Form.Group>
                          <label>Confirm Password</label>
                          <InputGroup><Form.Control
                          placeholder="Password"
                          value={confirmPassword}
                          onChange={event => setConfirmPassword(event.target.value)}
                          isInvalid={showErrorConfirm}
                          type={
                            passwordMaskConfirm
                              ? 'password'
                              : 'text'
                          }
                        ></Form.Control>
                                <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={togglePasswordVisibilityConfirm} className={passwordMaskConfirm ? "text-primary" : "text-secondary"}>
                                  {passwordMaskConfirm ? <EyeSlash /> : <Eye />}
                                </Button>
                              </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                          {showErrorConfirm}
                        </Form.Control.Feedback></InputGroup>
                      </Form.Group>                      
                  </Form>
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleClosePassword}
                    variant="link"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={handleChangePassword}
                    variant="outlined"
                    color="primary"
                    disabled={!password || !confirmPassword}
                  >
                    Save
                  </Button>
                </div>
              </Modal>

              <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete User</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this user? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteUser(deleteAdminUserIdPassword)}
                    variant="danger"
                    color="primary"
                  >
                    Delete User
                  </Button>
                </div>
              </Modal>
              </>
            )
          }}
        </Query>
      );
}

export default withSession(Team);
