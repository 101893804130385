/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { ApolloProvider } from 'react-apollo'
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error'
import includes from 'lodash/includes'
import DynamicHead from './components/DynamicHead/index';


import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";
import Router from "router";

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token')
    if (token) {
      headers = { ...headers, 'x-token': token, app: 'admin' }
    }

    return { headers }
  })

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const expired = includes(message, 'Your session expired')

      if (message === 'UNAUTHENTICATED' || expired) {
        // signOut(client)
        localStorage.removeItem("token");
      }
    })
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      // signOut(client)
      localStorage.removeItem("token");
    }
  }
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_URI,
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <DynamicHead site={window.location.hostname}/>
    <Router />
  </ApolloProvider>,
  document.getElementById("root")
);
