import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import withSession from "./withSession";
import { ADD_MEDITATION_TO_CLIENT, UPDATE_MEDITATION, DELETE_MEDITATION } from "./mutations";

function MeditationManager(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [age, setAge] = React.useState('')
    const [description, setMeditationDescription] = React.useState('')
    const [audioLink, setAudioLink] = React.useState('')
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editMeditationId, seteditMeditationId] = React.useState('')
    const [deleteMeditationId, setDeleteMeditationId] = React.useState('')


    const [addMeditationToClient] = useMutation(ADD_MEDITATION_TO_CLIENT)
    const [updateMeditation] = useMutation(UPDATE_MEDITATION)
    const [deleteMeditation] = useMutation(DELETE_MEDITATION)

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setAge('')
      setMeditationDescription('')
      setAudioLink('')
      seteditMeditationId('')
    }

    const handleOpenDelete = (meditation) => {
      setDeleteModal(true)
      setDeleteMeditationId(meditation._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteMeditationId('')
    }

    const handleAddMeditation = () => {
      let meditation = {
        title,
        age,
        description,
        audioLink,
      }

      addMeditationToClient({
        variables: {
          name: props.session.me.clientName,
          meditation
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (meditation) => {
      setModal(true)
      setTitle(meditation.title)
      setAge(meditation.age)
      setMeditationDescription(meditation.description)
      setAudioLink(meditation.audioLink)
      seteditMeditationId(meditation._id)
      setShowError('')
    }

    const handleEditMeditation = async (editMeditationId) => {
      const meditationUpdate = {
        title,
        age,
        description,
        audioLink,
      }
      try {
        await updateMeditation({
          variables: {
            meditationId: editMeditationId,
            name: props.session.me.clientName,
            meditationUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating meditation:', error);
      }
    }

    const handleDeleteMeditation = async (deleteMeditationId) => {
      try {
        await deleteMeditation({
          variables: {
            meditationId: deleteMeditationId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating meditation:', error);
      }
    }

    const ageOptions = [
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
      { value: "16", label: "16" },
      { value: "17", label: "17" },
      { value: "18", label: "18" },
      { value: "Adult", label: "Adult" }
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { meditations, adminUsers = [] } = getClient || {}
            
            const tableData = map(meditations, (meditation, key) => {
              return {
              id: key,
              ...meditation,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(meditation)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(meditation)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Meditation Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                  Header: "Age",
                                  accessor: "age",
                                  Cell: ({ value }) => value ? value.join(", ") : ""

                               },
                                {
                                    Header: "Description",
                                    accessor: "description",
                                },
                                {
                                    Header: "Audio Link",
                                    accessor: "audioLink",
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Meditation</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                    >
                  <Modal.Header className="justify-content-center">
                    <label>{editMeditationId ? 'Edit' : 'Add'} Meditation</label>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#" method="#">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                value={title}
                                placeholder="Title"
                                type="string"
                                onChange={event => setTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Age</label>
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Ages"
                                name="multipleAgeSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={(age || []).map(a => {
                                  const matchingOption = ageOptions.find(option => option.value === a || option.value === a.value);
                                  return matchingOption || { value: a, label: a.label || a };
                                })}
                                onChange={(selectedOptions) => {
                                  // If selectedOptions is null or undefined, default to an empty array
                                  const agesValues = (selectedOptions || []).map(option => {
                                    return typeof option === 'object' ? option.value : option;
                                  });
                                
                                  setAge(agesValues);
                                }}
                                options={ageOptions} // Use the options array here
                              />
                        </Form.Group>
                        <Form.Group>
                            <label>Description</label>
                            <Form.Control
                                value={description}
                                placeholder="Description"
                                type="string"
                                onChange={event => setMeditationDescription(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Audio Link</label>
                            <Form.Control
                                value={audioLink}
                                placeholder="Audio Link"
                                type="string"
                                onChange={event => setAudioLink(event.target.value)}
                            />
                        </Form.Group>
                    </Form>
                  </Modal.Body>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={handleClose}
                      variant="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={editMeditationId ? () => handleEditMeditation(editMeditationId) : handleAddMeditation}
                      variant="outlined"
                      color="primary"
                      disabled={!title || !age || !description || !audioLink}
                    >
                      Save
                    </Button>
                  </div>
                </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete Meditation</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Meditation? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteMeditation(deleteMeditationId)}
                    variant="danger"
                    color="primary"
                  >
                    Delete Meditation
                  </Button>
                </div>
              </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(MeditationManager);
