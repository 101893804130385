/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Admin from "views/Admin.js";
import Dashboard from "views/Dashboard.js";
import DashboardTemplate from "views/DashboardTemplate.js";
import Settings from "views/Settings.js";
import Team from "views/Team.js";
import AppUsers from "views/AppUsers.js";
import ContentManager from "views/ContentManager.js";
import LessonManager from "views/LessonManager.js";
import MeditationManager from "views/MeditationManager.js";
import MovementManager from "views/MovementManager.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import CreateAccount from "views/Pages/CreateAccountPage.js";
import CreateAccountPlan from "views/Pages/CreateAccountPlan.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import Chat from "views/Chat.js";
import TutorialManager from "views/TutorialManager";
import ConfirmationPage from "views/Pages/ConfirmationPage";
import TermsOfUse from "views/Pages/TermsOfUse";
import PrivacyPolicy from "views/Pages/PrivacyPolicy";

var routes = [
  {
    path: "/create-account-page",
    layout: "/auth",
    name: "Create Account Page",
    // mini: "LP",
    component: CreateAccount,
  },
  {
    path: "/confirmation-page",
    layout: "/auth",
    name: "Confirmation Page",
    // mini: "LP",
    component: ConfirmationPage,
  },
  {
    path: "/terms-of-use",
    layout: "/auth",
    name: "Terms of Use Page",
    // mini: "LP",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    layout: "/auth",
    name: "Privacy Policy Page",
    // mini: "LP",
    component: PrivacyPolicy,
  },
  {
    path: "/create-account-plan",
    layout: "/auth",
    name: "Create Account Plan",
    // mini: "LP",
    component: CreateAccountPlan,
  },
  {
    path: "/login-page",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
  },
  // {
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-grid-45",
  //   component: Dashboard
  // },
  // {
  //   path: "/dashboardTemplate",
  //   layout: "/admin",
  //   name: "DashboardTemplate",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: DashboardTemplate
  // },
  {
    path: "/settings",
    layout: "/admin",
    name: "Settings",
    icon: "nc-icon nc-preferences-circle-rotate",
    component: Settings,
    clients: ["mendedLight", "Ultimind", "feelwise", "regulate", "lumify"],
  },
  {
    path: "/chat",
    layout: "/admin",
    name: "Chat",
    icon: "nc-icon nc-chat-round",
    component: Chat,
    clients: ["Ultimind", "feelwise", "regulate", "lumify"],
  },
  {
    path: "/team",
    layout: "/admin",
    name: "Team",
    icon: "nc-icon nc-grid-45",
    component: Team,
    clients: ["mendedLight", "Ultimind", "feelwise", "regulate", "lumify"],
  },
  {
    path: "/appusers",
    layout: "/admin",
    name: "App Users",
    icon: "nc-icon nc-mobile",
    component: AppUsers,
    clients: ["mendedLight", "Ultimind", "feelwise", "regulate", "lumify"],
  },
  {
    path: "/instructionalpages",
    layout: "/admin",
    name: "Instructional Pages",
    icon: "nc-icon nc-compass-05",
    component: TutorialManager,
    clients: ["feelwise", "regulate", "lumify"],
  },
  {
    path: "/contentmanager",
    layout: "/admin",
    name: "Content Manager",
    icon: "nc-icon nc-backpack",
    component: ContentManager,
    clients: ["feelwise", "regulate", "lumify"],
  },
  // {
  //   path: "/lessonmanager",
  //   layout: "/admin",
  //   name: "Lesson Manager",
  //   icon: "nc-icon nc-backpack",
  //   component: LessonManager,
  //   clients: ['feelwise', 'regulate', 'lumify']
  // },
  // {
  //   path: "/meditationmanager",
  //   layout: "/admin",
  //   name: "Meditation Manager",
  //   icon: "nc-icon nc-backpack",
  //   component: MeditationManager,
  //   clients: ['feelwise', 'regulate', 'lumify']
  // },
  // {
  //   path: "/movementmanager",
  //   layout: "/admin",
  //   name: "Movement Manager",
  //   icon: "nc-icon nc-backpack",
  //   component: MovementManager,
  //   clients: ['feelwise', 'regulate', 'lumify']
  // },
  // {
  //   path: "/admin",
  //   layout: "/admin",
  //   name: "Admin",
  //   icon: "nc-icon nc-settings-gear-64",
  //   component: Admin
  // },
  // {
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons
  //     },
  //     {
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem
  //     },
  //     {
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels
  //     },
  //     {
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert
  //     },
  //     {
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications
  //     },
  //     {
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons
  //     },
  //     {
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms
  //     },
  //     {
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms
  //     },
  //     {
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms
  //     },
  //     {
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables
  //     },
  //     {
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables
  //     },
  //     {
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap
  //     },
  //     {
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap
  //     }
  //   ]
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts
  // },
  // {
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar
  // },
  // {
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     {
  //       path: "/user-page",
  //       layout: "/admin",
  //       name: "User Page",
  //       mini: "UP",
  //       component: UserPage
  //     },
  //     {
  //       path: "/register-page",
  //       layout: "/auth",
  //       name: "Register",
  //       mini: "RP",
  //       component: RegisterPage
  //     },
  //     {
  //       path: "/lock-screen-page",
  //       layout: "/auth",
  //       name: "Lock Screen Page",
  //       mini: "LSP",
  //       component: LockScreenPage
  //     }
  //   ]
  // }
];
export default routes;
