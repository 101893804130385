import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import withSession from "./withSession";
import { ADD_LESSON_TO_CLIENT, UPDATE_LESSON, DELETE_LESSON } from "./mutations";

function LessonManager(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [age, setAge] = React.useState([])
    const [lessonNumber, setLessonNumber] = React.useState('')
    const [videoLink, setVideoLink] = React.useState('')
    const [activities, setActivities] = React.useState([]);
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editLessonId, setEditLessonId] = React.useState('')
    const [deleteLessonId, setDeleteLessonId] = React.useState('')
    // const [multipleActivities, setMultipleActivities] = React.useState("");


    const [addLessonToClient] = useMutation(ADD_LESSON_TO_CLIENT)
    const [updateLesson] = useMutation(UPDATE_LESSON)
    const [deleteLesson] = useMutation(DELETE_LESSON)

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setAge('')
      setLessonNumber('')
      setVideoLink('')
      setActivities([])
      setEditLessonId('')
    }

    const handleOpenDelete = (lesson) => {
      setDeleteModal(true)
      setDeleteLessonId(lesson._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteLessonId('')
    }

    const handleAddLesson = () => {
      let lesson = {
        title,
        age,
        lessonNumber,
        videoLink,
        activities
      }

      addLessonToClient({
        variables: {
          name: props.session.me.clientName,
          lesson
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (lesson) => {
      setModal(true)
      setTitle(lesson.title)
      setAge(lesson.age)
      setLessonNumber(lesson.lessonNumber)
      setVideoLink(lesson.videoLink)
      setActivities(lesson.activities)
      setEditLessonId(lesson._id)
      setShowError('')
    }

    const handleEditLesson = async (editLessonId) => {
      const lessonUpdate = {
        title,
        age,
        lessonNumber,
        videoLink,
        activities: activities
      }
      try {
        await updateLesson({
          variables: {
            lessonId: editLessonId,
            name: props.session.me.clientName,
            lessonUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating lesson:', error);
      }
    }

    const handleDeleteLesson = async (deleteLessonId) => {
      try {
        await deleteLesson({
          variables: {
            lessonId: deleteLessonId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating lesson:', error);
      }
    }

    const options = [
      { value: "daily check-in", label: "Daily Energy Check-in" },
      { value: "mood", label: "Mood" },
      { value: "journal", label: "Journal" },
      { value: "affirmations", label: "Affirmations" },
      { value: "body", label: "Body" },
      { value: "movement", label: "Movement" },
      { value: "breathe", label: "Breathe" },
      { value: "meditation", label: "Meditation" },
      { value: "visualization", label: "Visualization" }
    ];

    const ageOptions = [
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
      { value: "16", label: "16" },
      { value: "17", label: "17" },
      { value: "18", label: "18" },
      { value: "Adult", label: "Adult" }
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { lessons, adminUsers = [] } = getClient || {}
            
            const tableData = map(lessons, (lesson, key) => {
              return {
              id: key,
              ...lesson,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(lesson)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(lesson)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Lesson Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                    Header: "Age",
                                    accessor: "age",
                                    Cell: ({ value }) => value ? value.join(", ") : ""
                                },
                                {
                                    Header: "Lesson Description",
                                    accessor: "lessonNumber",
                                },
                                {
                                    Header: "Video Link",
                                    accessor: "videoLink",
                                },
                                {
                                  Header: "Activities",
                                  accessor: "activities",
                                  Cell: ({ value }) => value ? value.join(", ") : ""
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Lesson</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                    >
                  <Modal.Header className="justify-content-center">
                    <label>{editLessonId ? 'Edit' : 'Add'} Lesson</label>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#" method="#">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                value={title}
                                placeholder="Title"
                                type="string"
                                onChange={event => setTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Age</label>
                              <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Ages"
                                name="multipleAgeSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={(age || []).map(a => {
                                  const matchingOption = ageOptions.find(option => option.value === a || option.value === a.value);
                                  return matchingOption || { value: a, label: a.label || a };
                                })}
                                onChange={(selectedOptions) => {
                                  // If selectedOptions is null or undefined, default to an empty array
                                  const agesValues = (selectedOptions || []).map(option => {
                                    return typeof option === 'object' ? option.value : option;
                                  });
                                
                                  setAge(agesValues);
                                }}
                                options={ageOptions} // Use the options array here
                              />
                        </Form.Group>
                        <Form.Group>
                            <label>Lesson Description</label>
                            <Form.Control
                                value={lessonNumber}
                                placeholder="Lesson Description"
                                type="string"
                                onChange={event => setLessonNumber(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Video Link</label>
                            <Form.Control
                                value={videoLink}
                                placeholder="Video Link"
                                type="string"
                                onChange={event => setVideoLink(event.target.value)}
                            />
                        </Form.Group>
                        {/* <Form.Group>
                            <label>Activities</label>
                            <Form.Control
                                value={activities}
                                as="select"
                                onChange={event => setActivities(event.target.value)}
                            >
                                <option value="mood">Mood</option>
                                <option value="journal">Journal</option>
                                <option value="affirmations">Affirmations</option>
                                <option value="body">Body</option>
                                <option value="yoga">Yoga</option>
                                <option value="breath">Breath</option>
                                <option value="meditation">Meditation</option>
                                <option value="visualization">Visualization</option>
                            </Form.Control>
                        </Form.Group> */}
                      <Form.Group>
                          <label>Activities</label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Choose Activities"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={(activities || []).map(activity => {
                              const matchingOption = options.find(option => option.value === activity || option.value === activity.value);
                              return matchingOption || { value: activity, label: activity.label || activity };
                            })}
                            onChange={(selectedOptions) => {
                              // If selectedOptions is null or undefined, default to an empty array
                              const activitiesValues = (selectedOptions || []).map(option => {
                                return typeof option === 'object' ? option.value : option;
                              });
                            
                              setActivities(activitiesValues);
                            }}
                            options={options} // Use the options array here
                          />
                        </Form.Group>
                    </Form>
                  </Modal.Body>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={handleClose}
                      variant="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={editLessonId ? () => handleEditLesson(editLessonId) : handleAddLesson}
                      variant="outlined"
                      color="primary"
                      disabled={!title || !age || !lessonNumber || !videoLink || !activities}
                    >
                      Save
                    </Button>
                  </div>
                </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete Lesson</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Lesson? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteLesson(deleteLessonId)}
                    variant="danger"
                    color="primary"
                  >
                    Delete Lesson
                  </Button>
                </div>
              </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(LessonManager);
