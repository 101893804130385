import React, { useState } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Col
} from "react-bootstrap";
import talkAppsLogo from 'assets/img/talkapps-logo.png'

import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { useMutation } from "react-apollo";
import { SIGNIN } from "views/mutations";
import { GET_ME } from "views/queries";
import withSession from "views/withSession";

import includes from 'lodash/includes'

function LoginPage(props) {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState("");
  const [passwordMask, setPasswordMask] = useState(true)

  const [clientSignin] = useMutation(SIGNIN)

  const togglePasswordVisibility = () => {
    setPasswordMask(passwordMask ? false : true);
  }

  const isLocalHost = includes(process.env.REACT_APP_URI, 'localhost')

  var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;
  if (isLocalHost) {
    const urlParams = new URLSearchParams(window.location.search)
    subdomain = urlParams.get('subdomain')
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    await clientSignin({
      variables: {
        name: subdomain || 'mendedlight',
        email,
        password
      },
      refetchQueries: [
        { query: GET_ME }
      ]
    }).then(({ data }) => {
      console.log(data)
      localStorage.setItem('token', data.clientSignin)
      setShowError('')
      window.location.reload()
      props.refetch()
    }).catch(error => {
      console.log(error)
      setShowError('Username or Password is incorrect')
    })
    
  //   const headers = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Request": "*",
  //   };
  // console.log(headers)
  //   const data = {
  //       "email": email,
  //       "password": password
  //   };
  
  //   const options = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(data)
  //   };
  
  
  
  //   await fetch('/login', options)
  //   .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         setShowError('Username or Password is incorrect')
  //         throw new Error('Network response was not ok.');
  //       }
  //     })
  //     .then(data => {
  //         console.log("data",data)
  //         setShowError('')
  //           //SET THE COOKIE
  //         localStorage.setItem('token', data.token);
  //         location.href = "http://localhost:3000/admin/settings";
  //       }
  //     )
  //     .catch(error => console.error(error));
  
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg")}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form className="form" onSubmit={handleLogin}>
                <Card className={"card-login " + cardClasses}>
                  <Card.Header>
                  <div className="logo">
                        <img
                          src={talkAppsLogo}
                          className="d-flex justify-content-center align-items-center"
                          style={{maxHeight: 40, margin: "auto"}}
                        />
                  </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Email address</label>
                        <Form.Control
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          onChange={handleEmailChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        
                        <label>Password</label>
                        <InputGroup><Form.Control
                          placeholder="Password"
                          value={password}
                          onChange={handlePasswordChange}
                          isInvalid={showError}
                          type={
                            passwordMask
                              ? 'password'
                              : 'text'
                          }
                        ></Form.Control>
                                <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={togglePasswordVisibility} className={passwordMask ? "text-primary" : "text-secondary"}>
                                  {passwordMask ? <EyeSlash /> : <Eye />}
                                </Button>
                              </InputGroup.Append>
                        <Form.Control.Feedback type="invalid">
                          {showError}
                        </Form.Control.Feedback></InputGroup>
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className="btn-wd" type="submit" variant="warning">
                      Login
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("assets/img/full-screen-image-2.jpg") + ")"
          }}
        ></div>
      </div>
    </>
  );
}

export default withSession(LoginPage);
