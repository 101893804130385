import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Col,
} from "react-bootstrap";
import includes from "lodash/includes";

function AuthFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <nav>
            <p className="copyright text-center m-0">
              © {new Date().getFullYear()} {renderName()}
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

function renderName(option) {
  const isLocalHost = includes(process.env.REACT_APP_URI, "localhost");
  var subdomain = window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : null;
  if (isLocalHost) {
    const urlParams = new URLSearchParams(window.location.search);
    subdomain = urlParams.get("subdomain");
  }

  switch (subdomain) {
    case "feelwise":
      return "FeelWise";
    case "ultimind":
      return "Ultimind";
    case "mendedlight":
      return "MendedLight";
    case "regulate":
      return "Regulate";
    case "lumify":
      return "Lumify";
    default:
      return "MendedLight";
  }
}

export default AuthFooter;
