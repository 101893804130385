import React, { useEffect, useState } from "react";
import Select from 'react-select';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from "views/PaymentForm";
import { useMutation, useLazyQuery } from "react-apollo";
import { ADD_APP_USER } from "views/mutations";
import { SUBSCRIBE_FEELWISE } from "views/mutations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CHECK_COUPON } from "views/queries";

import round from 'lodash/round'

import './datePicker.css'
import { VERIFY_CODE } from "views/queries";
import { useHistory, Link } from 'react-router-dom';
import { includes, filter } from "lodash";


export const countries = [
  "United States",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  // "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]

const Step1 = React.forwardRef((props, ref) => {
    const [fName, setFName] = useState()
    const [lName, setLName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [birthday, setBirthday] = useState()
    const [country, setCountry] = useState()

    const isLocalHost = includes(process.env.REACT_APP_URI, 'localhost')

    let options = 
    isLocalHost ?
    [
      { value: 'monthlysingletest', label: 'The Buddy Plan ($9.95/mo)', planId: 'price_1P3jSyBKar2BpOhwIA9kSf1C', teamLimit: 1, price: 995, originalPrice: 29.95, discount: 20.00 },
      { value: 'yearlysingletest', label: 'The Buddy Plan ($99.95/yr)', planId: 'price_1P3jXJBKar2BpOhwIpn7UlC0', teamLimit: 1, price: 9995, originalPrice: 29.95, discount: 20.00 },
      { value: 'familymonthlytest', label: 'Friend and Family Shared Plan ($19.95/mo)', planId: 'price_1P3jZMBKar2BpOhwvdXDaFwm', teamLimit: 5, price: 1995, originalPrice: 49.95, discount: 30.00 },
      { value: 'yearlyfamilytest', label: 'Friend and Family Shared Plan ($199.00/yr)', planId: 'price_1P3jZpBKar2BpOhwDFi6du4L', teamLimit: 5, price: 19900, originalPrice: 588.00, discount: 389.00 },
    ]
    :
    [
      { value: 'monthlysingle', label: 'The Buddy Plan ($9.95/mo)', planId: 'price_1P6wHPBKar2BpOhwiiVjFs3A', teamLimit: 1, price: 995, originalPrice: 29.95, discount: 20.00 },
      { value: 'yearlysingle', label: 'The Buddy Plan ($99.95/yr)', planId: 'price_1P6wHRBKar2BpOhwS2akdJAE', teamLimit: 1, price: 9995, originalPrice: 29.95, discount: 20.00 },
      { value: 'familymonthly', label: 'Friend and Family Shared Plan ($19.95/mo)', planId: 'price_1P6wHfBKar2BpOhwzQGOGhxU', teamLimit: 5, price: 1995, originalPrice: 49.95, discount: 30.00 },
      { value: 'yearlyfamily', label: 'Friend and Family Shared Plan ($199.00/yr)', planId: 'price_1P6wHPBKar2BpOhwiiVjFs3A', teamLimit: 5, price: 19900, originalPrice: 49.95, discount: 30.00 },
    ];

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('defaultplan')) {
      if (searchParams.get('defaultplan') === 'buddy') {
        options = filter(options, option => {
          return includes(option.label, 'Buddy')
        })
      }
      if (searchParams.get('defaultplan') === 'shared') {
        options = filter(options, option => {
          return includes(option.label, 'Shared')
        })
      }
    }

    const defaultOption = searchParams.has('defaultplan') ? options[0] : { value: 'familymonthlytest', label: 'Friend and Family Shared Plan ($19.95/mo)', planId: isLocalHost ? 'price_1P3jZMBKar2BpOhwvdXDaFwm' : 'price_1P6wHfBKar2BpOhwzQGOGhxU', price: 1995 };
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    React.useImperativeHandle(ref, () => ({
      isValidated: isValidated,
      state: {
        fName,
        lName,
        email,
        password,
        confirmPassword,
        birthday,
        country,
      }
    }));

    function isValidated() {
      const allFields = Boolean(fName && lName && email && password && confirmPassword && birthday && country)
      const passwordMatch = Boolean(password === confirmPassword)
      if (!allFields) {
        alert("All fields are required");
        return false;
      } else if (!passwordMatch) {
        alert("Passwords don't match");
        return false;
      } else {
        return true;
      }
    }

  return (
    <div data-image={require("assets/img/FeelWiseMockup2.png")}>
      <div className="content d-flex align-items-center">
        <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
          <Card className="card-register card-plain text-center" style={{ marginTop: -40 }}>
            <Card.Body>
              <Row>
                <Col className="mr-auto ml-auto" md="7" lg="5" style={{ paddingRight: 0, paddingLeft: 0 }}>
                  {/* <img
                    alt="..."
                    style={{ width: 500 }}
                    src={require("assets/img/FeelWiseMockup2.png")}
                  /> */}
                  <div className="trial-info" style={{ textAlign: 'start', background: '#fff', padding: '10px', borderRadius: '8px' }}>
                    <span className="trial-text" style={{ textAlign: 'start', fontWeight: '600', fontSize: 24, color: '#00B4BC' }}>What's Included:</span>
                    <span style={{ textAlign: 'start', display: 'block', marginTop: '10px', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>
                      <span style={{ marginRight: 5 }}>▶</span>
                      {searchParams.has('defaultplan') ? searchParams.get('defaultplan') === 'shared' ? "Up to 6 users" : "Up to 2 users" : "Up to 6 users"}
                    </span>
                    <span style={{ textAlign: 'start', display: 'block', marginTop: '10px', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>
                      <span style={{ marginRight: 5 }}>▶</span>
                      Daily personalised check-ins.</span>
                    <span style={{ textAlign: 'start',display: 'block', marginTop: '10px', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>
                      <span style={{ marginRight: 5 }}>▶</span>
                      Personalized learning recommendations.</span>
                    <span style={{ textAlign: 'start',display: 'block', marginTop: '10px', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>
                      <span style={{ marginRight: 5 }}>▶</span>
                      Connect with your team and AI guide.</span>
                    <span style={{ textAlign: 'start',display: 'block', marginTop: '10px', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>
                      <span style={{ marginRight: 5 }}>▶</span>
                      Positive mental habits for daily practice.</span>
                  </div>
                  <div 
                    className="trial-info" 
                    style={{ background: '#fff', padding: '10px', borderRadius: '8px' }}
                    >
                    <span className="trial-text" style={{ fontWeight: '600', fontSize: 24, color: '#00B4BC' }}>Start Your 14 Day Free Trial Today!</span>
                    {/* <span className="due-today" style={{ display: 'block', marginTop: '10px' }}>Total due today <strong>$0.00</strong></span> */}
                    <span style={{ display: 'block', marginBottom: '10px', fontWeight: '600', fontSize: 18 }}>No charges will be processed today.</span>
                    <span style={{ display: 'block', marginTop: '10px', marginBottom: '10px', fontSize: 13 }}>After 14 days, we'll process a charge to your card on file, unless you decide to cancel prior to the end of your 14 Day Trial. Our Payments are 100% Safe & Secure.</span>
                  </div>
                  <div>
                    <img
                      style={{ width: 300 }} src={require("assets/img/safeCheckout5.png")}
                    />
                  </div>
                </Col>
                <Col className="mr-auto ml-auto" md="5" lg="4">
                  <Form style={{paddingBottom: '10px'}}>
                    <Card style={{ border: 'none'}}>
                      <div className="card-body" style={{ padding: 0 }}>
                        <div style={{ marginBottom: 10, fontWeight: '600', fontSize: 18 }}>
                          Create your account
                        </div>
                        <Form.Group>
                          <Form.Control
                            placeholder="First Name"
                            value={fName}
                            onChange={event => setFName(event.target.value)}
                            style={{
                              borderColor: 'black',
                              color: 'black',
                              borderWidth: 1,
                              padding: 10,
                              borderRadius: 50
                              }}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            placeholder="Last Name"
                            value={lName}
                            onChange={event => setLName(event.target.value)}
                            style={{
                              borderColor: 'black',
                              color: 'black',
                              borderWidth: 1,
                              padding: 10,
                              borderRadius: 50
                              }}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                        <Select
                            classNamePrefix="react-select"
                            placeholder="Choose Country"
                            name="countryDropdown"
                            closeMenuOnSelect={true}
                            value={country} // Controlled by the selectedOption state
                            onChange={setCountry}
                            options={countries.map(value => { return { value: value, label: value } })}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                borderColor: 'black',
                                color: 'black',
                                borderWidth: 1,
                                borderRadius: 80,
                                marginBottom: 15,
                              }),
                            }}
                          />
                          <DatePicker 
                            selected={birthday} 
                            onChange={setBirthday} 
                            placeholderText="Birthday"
                            customInput={
                              <Form.Control
                                style={{
                                  borderColor: 'black',
                                  color: 'black',
                                  borderWidth: 1,
                                  padding: 10,
                                  borderRadius: 50,
                                  marginBottom: 10,
                                }}
                              ></Form.Control>
                            }
                          />
                        </Form.Group>
                        <div style={{ marginBottom: 10, fontWeight: '600', fontSize: 18 }}>Create Login</div>
                        <Form.Group>
                          <Form.Control
                            placeholder="Email Address"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            style={{
                              borderColor: 'black',
                              color: 'black',
                              borderWidth: 1,
                              padding: 10,
                              borderRadius: 50
                              }}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            placeholder="Password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            style={{
                              borderColor: 'black',
                              color: 'black',
                              borderWidth: 1,
                              padding: 10,
                              borderRadius: 50
                            }}
                            type="password"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            placeholder="Password Confirmation"
                            value={confirmPassword}
                            onChange={event => setConfirmPassword(event.target.value)}
                            style={{
                              borderColor: 'black',
                              color: 'black',
                              borderWidth: 1,
                              padding: 10,
                              borderRadius: 50
                            }}
                            type="password"
                          ></Form.Control>
                        </Form.Group>
                      </div>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
      {/* <div
        className="full-page-background"
        style={{
          backgroundImage: "url(" + require("assets/img/FeelWiseMockup2.png") + ")"
        }}
      ></div> */}
    </div>
  );
})

export default Step1;
