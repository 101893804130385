import React from "react";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { Query, useMutation } from "react-apollo";
import { GET_CLIENT } from "./queries";

import map from 'lodash/map'
import withSession from "./withSession";
import { ADD_MOVEMENT_TO_CLIENT, UPDATE_MOVEMENT, DELETE_MOVEMENT } from "./mutations";

function MovementManager(props) {
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [modalPassword, setModalPassword] = React.useState(false);

    const [title, setTitle] = React.useState('')
    const [age, setAge] = React.useState('')
    const [description, setMovementDescription] = React.useState('')
    const [videoLink, setVideoLink] = React.useState('')
    const [showError, setShowError] = React.useState('')
    const [showErrorConfirm, setShowErrorConfirm] = React.useState('')

    const [editMovementId, seteditMovementId] = React.useState('')
    const [deleteMovementId, setDeleteMovementId] = React.useState('')


    const [addMovementToClient] = useMutation(ADD_MOVEMENT_TO_CLIENT)
    const [updateMovement] = useMutation(UPDATE_MOVEMENT)
    const [deleteMovement] = useMutation(DELETE_MOVEMENT)

    const handleClose = () => {
      setModal(false)
      setTitle('')
      setAge('')
      setMovementDescription('')
      setVideoLink('')
      seteditMovementId('')
    }

    const handleOpenDelete = (movement) => {
      setDeleteModal(true)
      setDeleteMovementId(movement._id)
    }

    const handleCloseDelete = (user) => {
      setDeleteModal(false)
      setDeleteMovementId('')
    }

    const handleAddMovement = () => {
      let movement = {
        title,
        age,
        description,
        videoLink,
      }

      addMovementToClient({
        variables: {
          name: props.session.me.clientName,
          movement
        },
        refetchQueries: [
          {
            query: GET_CLIENT,
            variables: {
              name: props.session.me.clientName,
            }
          }
        ]
      }).then(() => {
        handleClose()
      }).catch(error => {
        setShowError(error.message)
      })
    }

    const handleOpenEdit = (movement) => {
      setModal(true)
      setTitle(movement.title)
      setAge(movement.age)
      setMovementDescription(movement.description)
      setVideoLink(movement.videoLink)
      seteditMovementId(movement._id)
      setShowError('')
    }

    const handleEditMovement = async (editMovementId) => {
      const movementUpdate = {
        title,
        age,
        description,
        videoLink,
      }
      try {
        await updateMovement({
          variables: {
            movementId: editMovementId,
            name: props.session.me.clientName,
            movementUpdate,
          },
        }).then(() => {
          handleClose()
        }).catch(error => {
          setShowError(error.message)
        });
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating movement:', error);
      }
    }

    const handleDeleteMovement = async (deleteMovementId) => {
      try {
        await deleteMovement({
          variables: {
            movementId: deleteMovementId,
            name: props.session.me.clientName,
          },
        }).then(() => {
          handleCloseDelete()
        }).catch(error => {
          setShowError(error.message)
        });
        // Handle successful update, e.g., showing a success message or redirecting
      } catch (error) {
        // Handle update error, e.g., showing an error message
        console.error('Error updating movement:', error);
      }
    }

    const ageOptions = [
      { value: "13", label: "13" },
      { value: "14", label: "14" },
      { value: "15", label: "15" },
      { value: "16", label: "16" },
      { value: "17", label: "17" },
      { value: "18", label: "18" },
      { value: "Adult", label: "Adult" }
    ];

      return (
        <Query
          query={GET_CLIENT}
          variables={{
            name: props.session.me.clientName,
          }}
        >
          {({ loading, data, refetch }) => {
            if (loading)
              return "Loading..."
            const { getClient } = data
            const { movements, adminUsers = [] } = getClient || {}
            
            const tableData = map(movements, (movement, key) => {
              return {
              id: key,
              ...movement,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a edit kind of action */}
                  <Button
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                    onClick={() => handleOpenEdit(movement)}
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    className="text-danger btn-link edit"
                    onClick={() => handleOpenDelete(movement)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  </div>
                )
              }
            })
              return (
                <>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <h4 className="title">Movement Manager</h4>
                        {/* <p className="category">
                          A powerful react plugin handcrafted by our friends from{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            react-table
                          </a>
                          . It is a highly flexible tool, based upon the foundations of
                          progressive enhancement on which you can add advanced interaction
                          controls. Please check out their{" "}
                          <a
                            href="https://react-table.tanstack.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            full documentation.
                          </a>
                        </p> */}
                        <Card>
                          <Card.Body>
                            <ReactTable
                              data={tableData}
                              columns={[
                                {
                                    Header: "Title",
                                    accessor: "title",
                                },
                                {
                                    Header: "Age",
                                    accessor: "age",
                                    Cell: ({ value }) => value ? value.join(", ") : ""
                                },
                                {
                                    Header: "Description",
                                    accessor: "description",
                                },
                                {
                                    Header: "Video Link",
                                    accessor: "videoLink",
                                },
                                {
                                  Header: "Actions",
                                  accessor: "actions",
                                  sortable: false,
                                  filterable: false,
                              },
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs="12" style={{ marginTop: 25, textAlign: 'right' }}>
                        <Button onClick={() => setModal(!modal)} style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15, fontSize: 16, fontWeight: 'bold' }}>Add Movement</Button>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    className="modal-primary"
                    onHide={handleClose}
                    show={modal}
                    size='md'
                    >
                  <Modal.Header className="justify-content-center">
                    <label>{editMovementId ? 'Edit' : 'Add'} Movement</label>
                  </Modal.Header>
                  <Modal.Body>
                    <Form action="#" method="#">
                        <Form.Group>
                            <label>Title</label>
                            <Form.Control
                                value={title}
                                placeholder="Title"
                                type="string"
                                onChange={event => setTitle(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Age</label>
                            <Select
                                className="react-select info"
                                classNamePrefix="react-select"
                                placeholder="Choose Ages"
                                name="multipleAgeSelect"
                                closeMenuOnSelect={false}
                                isMulti
                                value={(age || []).map(a => {
                                  const matchingOption = ageOptions.find(option => option.value === a || option.value === a.value);
                                  return matchingOption || { value: a, label: a.label || a };
                                })}
                                onChange={(selectedOptions) => {
                                  // If selectedOptions is null or undefined, default to an empty array
                                  const agesValues = (selectedOptions || []).map(option => {
                                    return typeof option === 'object' ? option.value : option;
                                  });
                                
                                  setAge(agesValues);
                                }}
                                options={ageOptions} // Use the options array here
                              />
                        </Form.Group>
                        <Form.Group>
                            <label>Description</label>
                            <Form.Control
                                value={description}
                                placeholder="Description"
                                type="string"
                                onChange={event => setMovementDescription(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <label>Video Link</label>
                            <Form.Control
                                value={videoLink}
                                placeholder="Video Link"
                                type="string"
                                onChange={event => setVideoLink(event.target.value)}
                            />
                        </Form.Group>
                    </Form>
                  </Modal.Body>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={handleClose}
                      variant="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={editMovementId ? () => handleEditMovement(editMovementId) : handleAddMovement}
                      variant="outlined"
                      color="primary"
                      disabled={!title || !age || !description || !videoLink}
                    >
                      Save
                    </Button>
                  </div>
                </Modal>

                <Modal
                  className="modal-primary"
                  onHide={handleCloseDelete}
                  show={deleteModal}
                  size='md'
                  >
                <Modal.Header className="justify-content-center">
                  <label>Delete Movement</label>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this Movement? This action cannot be undone
                </Modal.Body>
                <div className="modal-footer">
                  <Button
                    className="btn-simple"
                    onClick={handleCloseDelete}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-simple"
                    onClick={() => handleDeleteMovement(deleteMovementId)}
                    variant="danger"
                    color="primary"
                  >
                    Delete Movement
                  </Button>
                </div>
              </Modal>
                </>
              )
            }
          }
        </Query>
      );
}

export default withSession(MovementManager);
