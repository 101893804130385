import { Query } from "react-apollo"
import { GET_ME } from "./queries"

const withSession = Component => props => {
  return (
    <Query query={GET_ME}>
      {({ loading, data, refetch }) => {
        if (loading)
          return "Loading..."
        return (
          <Component {...props} session={data} refetch={refetch} />
        )
      }}
    </Query>
  )
}

export default withSession